/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {oceanAirOptions} from '../helper';
import {api} from "../../services/service";// import {useUser} from "../../redux/reducers/userReducer";
// import {houseMasterOptions, oceanAirOptions} from '../helper';


const cargoTypeOptions = [
  {value: '', label: 'ALL'},
  {value: 'F', label: 'Forwarding'},
  {value: 'M', label: 'Moving'},
  {value: 'V', label: 'Vehicle'},
  {value: 'C', label: 'Courier'},
  {value: 'E', label: 'Ecommerce'},
  {value: 'U', label: 'UPS'},
  {value: 'P', label: 'Canada Post'},
];


class AccountProfitSearchFormManager extends FormManager {
  onSearch;
  constructor() {
    super({
      prefix: 'account-profit-search',
      fields: [
        {name: 'branchId', serverName: 'branchId', label: 'Branch', noDefOption: true, smallMargin: true},
        {name: 'cargoType', serverName: 'cargoType', label: 'Service', options: cargoTypeOptions, noDefOption: true, smallMargin: true},
        {name: 'carrierType', serverName: 'carrierType', label: 'Carrier Type', options: [{value: '', label: 'ALL'}, ...oceanAirOptions],
          noDefOption: true, smallMargin: true},
        {name: 'paidStatus', serverName: 'paidStatus', label: 'Paid', options: [{value: '', label: 'ALL'}, {value: 'PAID', label: 'Paid'}],
          noDefOption: true, smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    // const [formik, , fields, errors] = this.renderParams;
    // const user = useUser();
    const areaOptions = api.useAreaOptions(true);
    _f('branchId').options = [{value: '', label: 'ALL'}, ...areaOptions];

    // React.useEffect(() => {
    //   _c('currency', user.currency ?? 'CAD');
    // }, [])
    // const branchId = _v('branchId');
    // const cargoType = _v('cargoType');
    // const carrierType = _v('carrierType');
    // // const currency = _v('currency');
    // const hm = _v('HM');
    //
    // React.useEffect(() => {
    //   if (!(branchId === undefined) || !(cargoType === undefined) || !(carrierType === undefined) || !(hm === undefined)) {
    //     this.onSearch();
    //   }
    // }, [branchId, cargoType, carrierType, hm]);

    return (
      <>
        {_r('branchId')}
        {_r('cargoType')}
        {_r('carrierType')}
        {_r('paidStatus')}
      </>
    );
  }
}

export default AccountProfitSearchFormManager;
