/**
 * @flow
 */
import React from 'react';
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import Form, {renderField} from '../../components/Form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import logo from '../../shared/img/logo/logo@2x.png';
import {util, api} from '../../services/service';
import {resetAllDataGridInfo} from '../../redux/reducers/dataGridReducer';
import {useDispatch} from 'react-redux';
import {resetUser} from '../../redux/reducers/userReducer';
import {resetNoti} from '../../redux/reducers/notiReducer';

const fields = [
  {name: 'username', label: 'Username', required: true},
  {name: 'password', label: 'Password', type: 'password', required: true},
];

const Login = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    localStorage.removeItem('@token');
    resetAllDataGridInfo(dispatch);
    resetUser(dispatch);
    resetNoti(dispatch);
  }, []);
  const onToggleShowPassword = e => {
    e.preventDefault();
    const toggle = !showPassword;
    const passwordField = fields.find(i => i.name === 'password');
    passwordField.type = toggle ? 'text' : 'password';
    setShowPassword(toggle);
  };
  const passwordEndComponent = (
    <button
      className={`form__form-group-button${showPassword ? ' active' : ''}`}
      onClick={e => onToggleShowPassword(e)}
      type={'button'}
    >
      <EyeIcon />
    </button>
  );
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className={'flex center mb-32'}>
            <img src={logo} alt={'COSHIP'} className={'w-half'}/>
          </div>
          <Form
            fields={fields}
            doNotUseCard
            doNotUseButtons
            onSubmit={async values => {
              try {
                const {username: loginId, password} = values;
                const user = await api.login({loginId, password, trustDevice: false, isMobile: false});
                // const menus = user.menu.map(i => i[2]).flat();
                // 로그인에 성공한 후 서버에서 받은 시작<URL>로 이동하거나 처음 메뉴로 이동함
                // util.nav(user?.['first_url'] ? `/admin${user['first_url']}` :  `/admin${menus?.[0]?.[1]}`);
                // util.nav(`/admin/schedule/fullschedule`);
                if(user?.['noticeId'] > 0) {
                  util.nav(`/admin/dashboard/notice?nid=${user['noticeId']}`);
                } else {
                  util.nav(`/admin/schedule/fullschedule`);
                }
              } catch (error) {
                util.showError('Failed to login. Please try it again.');
              }
            }}
            render={(formik, fields, errors) => {
              return (
                <>
                  {renderField(formik, 'username', fields, errors, <AccountOutlineIcon />)}
                  {renderField(formik, 'password', fields, errors, <KeyVariantIcon />, passwordEndComponent)}
                  <div className={'flex right w-full mb-16'}>
                    <Link to={'/'}>Forgot a password?</Link>
                  </div>
                  <Button className={'w-full'} color={'primary'} type={'submit'}>LOGIN</Button>
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
