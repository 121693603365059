/**
 * @flow
 */
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {updateUser} from '../redux/reducers/userReducer';
import {showDialog} from '../redux/reducers/globalDialogReducer';
import {api, util} from './service';
import {array} from "prop-types";

const useDev = window.location.host === 'localhost:3000' || window.location.host === 'coship.homekling.com';
const API_URL_DEV = 'http://coship.homekling.com';
// const API_URL_DEV = 'https://bms.coship.ca';
// const API_URL_PRD = useDev ? API_URL_DEV : 'https://adm.coship.ca';
const API_URL_PRD = useDev ? API_URL_DEV : 'https://bms.coship.ca';

const API_URL = process.env.NODE_ENV === 'production' ? API_URL_PRD : API_URL_DEV;
const JUSO_URL = 'https://www.juso.go.kr/addrlink/addrEngApiJsonp.do';
const JUSO_KEY = 'devU01TX0FVVEgyMDIwMDUyMTA3MTAxMjEwOTc4MTg=';

// region Params
type CUDType = 'Add' | 'Edit' | 'Del';
type CargoType = 'Moving' | 'Forwarding' | 'Courier' | 'eCommerce';
type CargoSubType = 'M' | 'A' | 'U' | 'C';
type CargoDeskType = 'D' | 'P' | 'N';
type CurrencyType = 'CAD' | 'USD' | 'KRW';
export type CustomerType = 'B' | 'U' | 'V' | 'D';
type TrackingType = 'CoSHIP' | 'UPS' | 'Canada Post';

export interface ReqLoginParams {
  loginId: string;
  password: string;
  trustDevice?: boolean;
  isMobile?: boolean;
  timeDelta?: number;
}

export interface QueryListParams {
  page: number;
  rowCount: number;
  qryText: string; // ListContainer 시 여기에 craftId 넣어줌
  orderBy: string;
  isDesc: boolean;
  fromDate?: number;
  toDate?: number;
  cType?: CustomerType; // Customer 전용
  cargoType?: CargoType; // Cargo 전용
  subType?: CargoSubType; // Cargo 전용
  hblCode?: string; // Cargo 전용
  customerId?: number; // Cargo 전용
  partnerId?: number; // Cargo 전용
  accountType?: 'ALL' | 'UNPAID' | 'EXPENSE' | 'PAID'; // Cargo 전용
  currency?: 'ALL' | 'CAD' | 'KRW' | 'USD'; // Cargo 전용
  departureId?: number; // Cargo 전용
  destinationId?: number; // Cargo 전용
  onlyVehicle?: boolean; // Cargo 전용
  fileNo?: string; // Cargo 전용
  masterNo?: string; // Cargo 전용
  isAir?: boolean; // Cargo 전용
  cmId?: number; // Cargo 전용
  ccId?: number; // Cargo 전용
  branchId?: number; // Account/Moving 전용
  workType?: 'P' | 'D' | 'R', // Cargo 전용
  craftId?: number; // Account 전용
  poName?: string; // Account 전용
  city?: string; // Cargo 전용
  tpSize?: string; // Container 전용
  searchName?: string; // Container 전용
  searchVal?: string; // Container 전용
  vslVoy?: string; // Container 전용
  pol?: string; // Container 전용
  pod?: string; // Container 전용
  etd?: number; // Container 전용
  eta?: number; // Container 전용
  invType?: string; // Account 전용
  invNo?: string; // Account 전용
  blNo?: string; // Account 전용
  billToId?: number; // Account 전용
  atypeId?: number; // Account 전용
  unpaid?: boolean; // Account 전용
  isOverdue?: boolean; // Account 전용
  filingNo?: string;  // Account 전용
  staffId?: number; // Trade partner 전용
  partnerType?: string;  // Trade Partner 전용
  isAll?: boolean;  // Account 전용
  kind?: string; // BL List 전용
  carrierType?: string; // BL List 전용
  bound?: string; // BL List 전용
  HM?: string; // BL List 전용
  shipperConsignee?: string; // BL List 전용
  trackingNo?: string; // BL List 전용
  flightDate?: number; // BL List 전용
  isRequest?: boolean; // Account 전용
  bankInfo?: string; // Account 전용
  payType?: string; // Account 전용
  remark?: string; // Account 전용
  type?: string; // Request 전용
  status?: string; // Request 전용
  isActive?: boolean; // Partner 전용
  flag?: boolean; // BL List 전용
  teamMain?: string;  // Account 전용
  amount?: number;  // Account 전용
  kindArr?: Array;  // Schedule 전용
  paidRequest?: string; // Request 전용
  vendorInv?: string; // Request 전용
  statusNo?: number; // BL LIST 전용
  spaceId?: number; // BL LIST 전용
  departure?: string;    // moving
  destination?: string;  // moving
}

interface AreaCUDParams {
  areaId?: number;
  areaName?: string;
  startDate?: number | string;
  isBranch?: boolean;
  isActive?: boolean;
}

interface NoticeCUDParams {
  id?: number;
  title?: string;
  message?: string;
  branch_id?: number;
  pop_up?: boolean;
  attach_file?: string;
}

interface AccountTypeCUDParams {
  id?: number;
  accountType?: string;
  isActive?: boolean;
  isExpense?: boolean;
  price?: number;
  currency?: CurrencyType;
  remark?: number;
}

interface AirVesselCUDParams {
  id?: number;
  craftCode?: string;
  craftName?: string;
  isAir?: boolean;
  etdDate?: number;
  etaDate?: number;
  pol?: number;
  pod?: number;
  // freightFee?: number;
  // currency?: string;
}

interface ContainerCUDParams {
  id?: number;
  pId?: number;
  fileNo?: string;
  containerId?: string;
  craftId?: string;
  weight?: number;
  sealNo?: string;
  // mainFee?: number;
  // currency?: string;
  // extraFee?: number;
  // currency2?: string;
}

interface PermissionCUDParams {
  id?: number;
  pname?: string;
  remark?: string;
  menu?: Array;
}

interface StaffScheduleCUDParams {
  id?: number;
  userId?: number;
  isHoliday?: boolean;
  planDate?: number;
  planDate2?: number;
  planTime?: string;
  location?: string;
  purpose?: string;
  jobDesc?: string;
  dayCount?: number;
}

interface StaffHolidayCUDParams {
  id?: number;
  userId?: number;
  startDate?: number;
  endDate?: number;
  totalDays?: number;
  usedDays?: number;
  remark?: string;
}

interface StaffCUDParams {
  userId?: number;
  loginId?: string;
  password?: string;
  userName?: string;
  userName2?: string;
  email?: string;
  cphone?: string;
  hphone?: string;
  areaId?: number;
  empDate?: number;
  workEnd?: number;
  startDate?: number;
  endDate?: number;
  jobTitle?: number;
  auth?: number;
  isActive?: boolean;
  address?: string;
}

interface CustomerCUDParams {
  customerId?: number;
  password?: string;
  customerName?: string;
  customerName2?: string;
  email?: string;
  cphone?: string;
  hphone?: string;
  address?: string;
  city?: string;
  customsCode?: string;
  areaId?: number;
  rate?: number;
  isLogin?: boolean;
  remark?: string;
  postalCode?: string;
  cType?: CustomerType;
  pickupTime?: string;
  pickupDay?: string[];
}

interface CargoMainCUDParams {
  hbl?: string;
  customerId?: number;
  cargoType?: CargoType;
  subType?: CargoSubType;
  departure?: number;
  entryName?: string;
  visaType?: string;
  staffId?: number;
  partnerId?: number;
  tracking?: string;
  tCompany?: TrackingType;
  eteDate?: string;
  extraInfo?: string;
  vinInfo?: string;
  cphone?: string;
  hphone?: string;
  city?: string;
  address?: string;
  email?: string;
  realName?: string;
  engName?: string;
  pickupDate?: number;
  pickupTime?: string;
  deskType?: CargoDeskType;
  isAir?: boolean;
  totalPrice?: number;
  currency?: string;
}

interface CargoJobCUDParams {
  hbl?: string;
  jobId?: number;
  jobDate?: number;
  city?: string;
  address?: string;
  jobTime?: string;
  workers?: string;
  reqDate?: number;
  props?: string;
}

interface CargoWorkOrderCUDParams {
  id?: number;
  hbl?: string;
  branchId?: number;
  workType?: 'P' | 'D' | 'R',
  workDate?: number;
  city?: string;
  address?: string;
  workers?: string;
  phones?: string;
  workTime?: string;
  remark?: string;
  cbm?: number;
  tapeColor?: string;
  postal?: string;
  engAddress?: string;
  customs?: string;
  iscleared?: string;
  props?: string;
  deskType?: CargoDeskType;
  customs_code?: string;
  email?: string;
  consigneeName?: string;
  consigneeNameEng?: string;
}

interface CargoShipCUDParams {
  hbl?: string;
  id?: number;
  cmId?: number;
  ccId?: number;
  jobId?: number;
  cbm1?: number;
  cbm2?: number;
  weight?: number;
  pcount?: number;
  coverAmt?: number;
  coverType?: 'DTI' | 'DTO' | 'DTP';
  fileNo?: string;
  seqNo?: string;
  chargeWt: number;
  wlhStr: string;
}

interface CargoDestCUDParams {
  hbl?: string;
  jobId?: number;
  city?: string;
  address?: string;
  engAddress?: string;
  postal?: string;
  hphone?: string;
  cphone?: string;
  ddate?: string;
  dtime?: string;
  branchId?: string;
  customs?: string;
  isCleared?: boolean;
}

interface CargoAccountCUDParams {
  hbl?: string;
  id?: number;
  currency?: string;
  price?: number;
  atypeId?: number;
  branchId?: number;
  remark?: string;
}

interface CargoExpenseCUDParams {
  craftId?: number;
  id?: number;
  currency?: string;
  price?: number;
  atypeId?: number;
  branchId?: number;
  remark?: string;
}

interface CargoPayHistoryCUDParams {
  accountId?: number;
  jobId?: number;
  payAmount?: number;
  payDate?: number;
  payType?: 'Cash' | 'Cheque' | 'C.Card';
  remark?: string;
  rate?: number;
  currency?: CurrencyType;
}

interface CargoMessageCUDParams {
  hbl?: string;
  remarkFrom?: string;
  remarkTo?: string;
}

interface CargoECItemCUDParams {
  hbl?: string;
  id?: number;
  accountId?: number;
  inDate?: number;
  requestDate?: number;
  weight?: number;
  cbm?: number;
  itemName?: string;
  tracking?: string;
  price?: number;
  currency?: CurrencyType;
  payType?: string;
  last4?: string;
  remark?: string;
}

interface CargoWorkAssignCUDParams {
  id?: number;
  cargoType?: CargoType;
  workDate?: number;
  workTime?: string;
  staffId?: number;
  vehicleId?: number;
  assignList?: Array;
  remark?: string;
}

interface ForwardTradePartnerCUDParams {
  id?: number;
  partnerType?: string;
  branchId?: string;
  staff?: string;
  staffId?: number;
  importNo?: string;
  engName?: string;
  localName?: string;
  country?: string;
  city?: string;
  postalCode?: string;
  alias?: string;
  province?: string;
  localAddress?: string;
  billAddress?: string;
  blName?: string;
  ceo?: string;
  idNo?: string;
  taxidNo?: string;
  corpNo?: string;
  accGroupId?: string;
  iata?: string;
  shipperId?: string;
  prefix?: string;
  scac?: string;
  cbsaScac?: string;
  kams?: string;
  isActive?: boolean;
  phone?: string;
  cell?: string;
  email?: string;
  remark?: string;
  jobId?: string;
  discountRate?: number;
  pwd?: string;
  pickupDay?: Array;
  pickupTime?: string;
  venderId?: string;
}

interface ForwardBLEntryParams {
  id?: number;
  blNo?: number;
  filingNo?: number;
  HM?: 'H' | 'M';
  carrierType?: 'O' | 'A';
  carrierBkgNo?: string;
  bound?: 'E' | 'I';
  blType?: string;
  postDate?: number;
  branchId?: number;
  jcommon?: string;
  jcustomer?: string;
  jvessel?: string;
  jroute?: string;
  jshipment?: string;
}

// endregion

class API {
  token;
  dispatch;
  history;
  async translateAddress(korAddr: string) {
    const formData = new FormData();
    formData.append('confmKey', JUSO_KEY);
    formData.append('keyword', korAddr);
    formData.append('resultType', 'json');
    const res = await axios.request({
      url: JUSO_URL,
      method: 'POST',
      data: formData
    });
    const data = JSON.parse(res.data.substring(1, res.data.length - 1));
    const {results: {juso}} = data;
    if (juso?.length > 0) {
      const {roadAddr: engAddress, zipNo: postal} = juso[0];
      return {engAddress, postal};
    } else {
      return null;
    }
  }
  async login(data: ReqLoginParams) {
    const action = 'ReqLogin';
    const timeDelta = new Date().getTimezoneOffset();
    const reqData = {action, data: {...data, timeDelta}};
    const {data: res} = await this.sendList('login', reqData);
    this.token = res[0].token;
    localStorage.setItem('@token', res[0].token);
    updateUser(this.dispatch, res[0]);
    return res[0];
  }
  async queryUser() {
    const {data} = await this.sendList('api/MenuList', {action: 'MenuList', data: {}});
    return data?.[0];
  }
  async logout() {
    this.token = undefined;
    localStorage.removeItem('@token');
  }
  async searchCustomer(qryText, rowCount) {
    return this.search('Customer');
  }
  async searchPartner(qryText, rowCount) {
    return this.search('Partner');
  }
  async searchStaff(qryText, rowCount) {
    return this.search('Staff');
  }
  async search(action, qryText, rowCount) {
    return this.sendList('api/FindData', {action, data: {qryText, rowCount}});
  }
  async getHolidayCount(id) {
    return this.send('api/GetHolidayCount', {action: 'GetHolidayCount', data: {id}});
  }
  async getAreaOptions(isBranch) {
    return this.sendList('api/BranchList', {action: 'AreaList', data: {}});
  }
  async getPermissionOptions() {
    return this.sendList('api/PermiList', {action: 'PermiList', data: {}});
  }
  async getNotiCount() {
    return this.sendList('api/NotiCount', {action: 'NotiCount', data: {}}, true);
  }
  async getJobTitleOptions() {
    return this.sendList('api/JobTitleList', {action: 'JobTitleList', data: {}});
  }
  async getCargoCustomerData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'CustomerInfo', data: {hblCode}});
  }
  async getDropZones() {
    return this.sendList('api/FindData', {action: 'Dropzone', data: {}});
  }
  useAreaOptions(isBranch: boolean) {
    const [options, setOptions] = React.useState([]);
    React.useState(() => {
      this.getAreaOptions(isBranch).then(({data}) => {
        const newOptions = data.areas.map(([value, label]) => ({value, label}));
        setOptions([...newOptions, {value: 0, label: 'CJ + SEOUL'}]);
      }).catch();
    }, []);
    return options;
  }
  usePermissionOptions() {
    const [options, setOptions] = React.useState([]);
    React.useState(() => {
      this.getPermissionOptions().then(({data}) => {
        const newOptions = data.permissions.map(([value, label]) => ({value, label}));
        setOptions(newOptions);
      }).catch();
    }, []);
    return options;
  }
  useJobTitleOptions() {
    const [options, setOptions] = React.useState([]);
    React.useState(() => {
      this.getJobTitleOptions().then(({data}) => {
        const newOptions = data['jtitles'].map(([value, label]) => ({value, label}));
        setOptions(newOptions);
      }).catch();
    }, []);
    return options;
  }
  useCargoCustomerData(hblCode: string) {
    const [info, setInfo] = React.useState({});
    React.useState(() => {
      this.getCargoCustomerData(hblCode).then((res) => {
      }).catch();
    }, []);
    return info;
  }
  useDropzoneOptions() {
    const [options, setOptions] = React.useState([]);
    React.useState(() => {
      this.getDropZones().then(({data: {finds}}) => {
        const newOptions = finds.map(([value, ,label, {Address: address, PickupDay: pickupDay, PickupTime: pickupTime}]) => ({value, label, address, pickupDay, pickupTime}));
        setOptions(newOptions);
      }).catch();
    }, []);
    return options;
  }
  useVehicleOptions() {
    const [options, setOptions] = React.useState([]);
    React.useState(() => {
      this.search('Vehicle', '', 100).then(({data: {finds}}) => {
        const newOptions = finds.map(([value,,label]) => ({value, label}));
        setOptions(newOptions);
      }).catch();
    }, []);
    return options;
  }
  async messageList(data: QueryListParams) {
    return this.sendList('dashboard/ListData', {action: 'ListMessage', data});
  }
  async setReadMessage(noticeId: number) {
    return this.send('api/SetFlag', {action: 'ReadMessage', data: {noticeId}});
  }
  async customerList(data: QueryListParams) {
    return this.sendList('customers/ListData', {action: 'ListCustomer', data});
  }
  async customerAdd(data: CustomerCUDParams) {
    return this.sendCUD('customers/CUD', {action: 'AddCustomer', data: this.getCustomerData(data)});
  }
  async customerEdit(data: CustomerCUDParams) {
    return this.sendCUD('customers/CUD', {action: 'EditCustomer', data: this.getCustomerData(data)});
  }
  async customerDel(data: CustomerCUDParams) {
    return this.sendCUD('customers/CUD', {action: 'DelCustomer', data: this.getCustomerData(data)});
  }
  async customerCommission(customerId: number) {
    return this.sendList('customers/GetData', {action: 'GetCommission', data: {customerId}});
  }
  async customerCommissionSave(data) {
    return this.sendCUD('customers/CUD', {action: 'EditCommission', data});
  }
  async staffList(data: QueryListParams) {
    return this.sendList('staffs/ListData', {action: 'ListStaff', data});
  }
  async staffData(uid: number) {
    return this.sendList('staffs/ListData', {action: 'StaffData', data: {uid}});
  }
  async staffAdd(data: StaffCUDParams) {
    return this.sendCUD('staffs/CUD', {action: 'AddStaff', data: this.getStaffData(data)});
  }
  async staffEdit(data: StaffCUDParams) {
    return this.sendCUD('staffs/CUD', {action: 'EditStaff', data: this.getStaffData(data)});
  }
  async staffDel(data: StaffCUDParams) {
    return this.sendCUD('staffs/CUD', {action: 'DelStaff', data: this.getStaffData(data)});
  }
  async addPort(data) {
    const {portName, portType, country, fullName} = data;
    return this.sendCUD('craft/CUD', {action: 'SetPort', data: {id: 0, portName, portType, country, fullName}});
  }
  async editPort(data) {
    const {id, portName, portType, country, fullName} = data;
    return this.sendCUD('craft/CUD', {action: 'SetPort', data: {id, portName, portType, country, fullName}});
  }
  async setVessel(data) {
    const {id, vesselName, carrierId, country} = data;
    return this.sendCUD('craft/CUD', {action: 'SetVessel', data: {id, vesselName, carrierId, country}});
  }
  async addVessel(data) {
    return this.setVessel(data);
  }
  async editVessel(data) {
    return this.setVessel(data);
  }
  async areaList(data: QueryListParams) {
    return this.sendList('settings/ListData', {action: 'ListArea', data});
  }
  async areaAdd(data: AreaCUDParams) {
    let {areaId = 0, areaName, startDate = 0, isActive = false, isBranch = false} = data;
    if (typeof startDate === 'string') {
      startDate = this.toTS(startDate);
    }
    return this.sendCUD('settings/CUD', {action: 'AddArea', data: {areaId, areaName, startDate, isActive, isBranch}});
  }
  async areaEdit(data: AreaCUDParams) {
    let {areaId = 0, areaName, startDate = 0, isActive = false, isBranch = false} = data;
    if (typeof startDate === 'string') {
      startDate = this.toTS(startDate);
    }
    return this.sendCUD('settings/CUD', {action: 'EditArea', data: {areaId, areaName, startDate, isActive, isBranch}});
  }
  async areaDel(data: AreaCUDParams) {
    return this.sendCUD('settings/CUD', {action: 'DelArea', data});
  }
  async noticeList(data: QueryListParams) {
    return this.sendList('settings/ListData', {action: 'ListNotice', data});
  }
  async getNotice(id) {
    return this.sendList('settings/GetData', {action: 'GetNotice', data: {id}});
  }
  async noticeAdd(data: NoticeCUDParams) {
    // let {id = 0, title, message, pop_up, branch_id, attach_file} = data;
    // return this.sendCUD('settings/CUD', {action: 'AddNotice', data: {id, title, message, isPublish, areaId}});
    return this.sendCUD('settings/CUD', {action: 'AddNotice', data});
  }
  async noticeEdit(data: NoticeCUDParams) {
    // let {id = 0, title, message, pop_up, branch_id, attach_file} = data;
    // return this.sendCUD('settings/CUD', {action: 'EditNotice', data: {id, title, message, isPublish, areaId}});
    return this.sendCUD('settings/CUD', {action: 'EditNotice', data});
  }
  async noticeDel(data: NoticeCUDParams) {
    let {id} = data;
    return this.sendCUD('settings/CUD', {action: 'DelNotice', data: {id}});
  }
  async accountTypeList(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListAccountType', data});
  }
  async accountTypeAdd(data: AccountTypeCUDParams) {
    let {id, accountType, currency, price, isActive, isExpense, remark, rewardRate} = data;
    return this.sendCUD('account/CUD', {action: 'AddAccountType', data: {id, accountType, currency, price, isActive, isExpense, remark, rewardRate}});
  }
  async accountTypeEdit(data: AccountTypeCUDParams) {
    let {id, accountType, currency, price, isActive, isExpense, remark, rewardRate} = data;
    return this.sendCUD('account/CUD', {action: 'EditAccountType', data: {id, accountType, currency, price, isActive, isExpense, remark, rewardRate}});
  }
  async accountTypeDel(data: AccountTypeCUDParams) {
    let {id} = data;
    return this.sendCUD('account/CUD', {action: 'DelAccountType', data: {id}});
  }
  async vehicleAdd({id, vName, remark}) {
    return this.sendCUD('settings/CUD', {action: 'AddVehicle', data: {id, vName, remark}});
  }
  async vehicleEdit({id, vName, remark}) {
    return this.sendCUD('settings/CUD', {action: 'EditVehicle', data: {id, vName, remark}});
  }
  async vehicleDel({id}) {
    return this.sendCUD('settings/CUD', {action: 'DelVehicle', data: {id}});
  }
  airVesselList(data: QueryListParams) {
    return this.sendList('craft/ListData', {action: 'ListAirVessel', data});
  }
  airVesselAdd(data: AirVesselCUDParams) {
    const reqData = this.getAirVesselData(data);
    return this.sendCUD('craft/CUD', {action: 'AddAirVessel', data: reqData});
  }
  airVesselEdit(data: AirVesselCUDParams) {
    const reqData = this.getAirVesselData(data);
    return this.sendCUD('craft/CUD', {action: 'EditAirVessel', data: reqData});
  }
  airVesselDel(data: AirVesselCUDParams) {
    const reqData = this.getAirVesselData(data);
    return this.sendCUD('craft/CUD', {action: 'DelAirVessel', data: reqData});
  }
  containerList(id: number) {
    return this.sendList('craft/ListData', {action: 'ListContainer', data: {qryText: id + ''}});
  }
  containerAdd(data: ContainerCUDParams) {
    const reqData = this.getContainerData(data);
    return this.sendCUD('craft/CUD', {action: 'AddContainer', data: reqData});
  }
  containerEdit(data: ContainerCUDParams) {
    const reqData = this.getContainerData(data);
    return this.sendCUD('craft/CUD', {action: 'EditContainer', data: reqData});
  }
  containerDel(data: ContainerCUDParams) {
    const reqData = this.getContainerData(data);
    return this.sendCUD('craft/CUD', {action: 'DelContainer', data: reqData});
  }
  async permissionList(data: QueryListParams) {
    return this.sendList('staffs/ListData', {action: 'ListPermission', data});
  }
  async permissionCUD(type: CUDType, data: PermissionCUDParams) {
    let {id, pname, remark, menu} = data;
    const action = `${type}Permission`;
    const reqData = type === 'Del' ? {id} : {id, pname, remark, menu};
    return this.sendCUD('staffs/CUD', {action, data: reqData});
  }
  async permissionAdd(data: PermissionCUDParams) {
    let {id, pname, remark, menu} = data;
    return this.sendCUD('staffs/CUD', {action: 'AddPermission', data: {id, pname, remark, menu}});
  }
  async permissionEdit(data: PermissionCUDParams) {
    let {id, pname, remark, menu} = data;
    return this.sendCUD('staffs/CUD', {action: 'EditPermission', data: {id, pname, remark, menu}});
  }
  async permissionDel(data: PermissionCUDParams) {
    return this.sendCUD('staffs/CUD', {action: 'DelPermission', data});
  }
  async getMenuPermissions(id: number) {
    const {data} = await this.sendList('settings/ListData', {action: 'ListMenubyAuth', data: {id}});
    return data;
  }
  loginLogList(data: QueryListParams) {
    return this.sendList('logs/ListLogLogin', {action: 'ListLogLogin', data});
  }
  activityLogList(data: QueryListParams) {
    return this.sendList('logs/ListLogActivity', {action: 'ListLogActivity', data});
  }
  staffScheduleList(data: QueryListParams) {
    return this.sendList('staffs/ListData', {action: 'ListPlan', data});
  }
  staffScheduleAdd(data: StaffScheduleCUDParams) {
    const reqData = this.getStaffScheduleData(data);
    return this.sendCUD('staffs/CUD', {action: 'AddPlan', data: reqData});
  }
  staffScheduleEdit(data: StaffScheduleCUDParams) {
    const reqData = this.getStaffScheduleData(data);
    return this.sendCUD('staffs/CUD', {action: 'EditPlan', data: reqData});
  }
  staffScheduleDel(data: StaffScheduleCUDParams) {
    const reqData = this.getStaffScheduleData(data);
    return this.sendCUD('staffs/CUD', {action: 'DelPlan', data: reqData});
  }
  staffHolidayList(data: QueryListParams) {
    return this.sendList('staffs/ListData', {action: 'ListHoliday', data});
  }
  staffHolidayAdd(data: StaffHolidayCUDParams) {
    const reqData = this.getStaffHolidayData(data);
    return this.sendCUD('staffs/CUD', {action: 'AddHoliday', data: reqData});
  }
  staffHolidayEdit(data: StaffHolidayCUDParams) {
    const reqData = this.getStaffHolidayData(data);
    return this.sendCUD('staffs/CUD', {action: 'EditHoliday', data: reqData});
  }
  staffHolidayDel(data: StaffHolidayCUDParams) {
    const reqData = this.getStaffHolidayData(data);
    return this.sendCUD('staffs/CUD', {action: 'DelHoliday', data: reqData});
  }
  staffHolidayCopy(data: StaffHolidayCUDParams) {
    const reqData = this.getStaffHolidayData(data);
    useDev && console.log('reqData: ', reqData);
    return this.sendCUD('staffs/CUD', {action: 'CopyHoliday', data: reqData});
  }
  getStaffHolidayHistory(userId: number) {
    return this.sendList('staffs/ListData', {action: 'ListHolidayHistory', data: {hId: userId}});
  }
  getCustomerInfo(customerId: number) {
    return this.sendList('api/GetCustomerInfo', {action: 'ReqGetCustomerInfo', data: {customerId}});
  }
  cargoList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoMain', data})
  }
  cargoDetail(hblCode: string) {
    return this.sendList('cargo/ViewData', {action: 'ViewCargoMain', data: {hblCode}})
  }
  cargoMainAdd(data: CargoMainCUDParams) {
    const reqData = this.getCargoMainData(data);
    return this.sendCUD('cargo/CUD', {action: 'AddCargoMain', data: reqData});
  }
  cargoMainEdit(data: CargoMainCUDParams) {
    const reqData = this.getCargoMainData(data);
    return this.sendCUD('cargo/CUD', {action: 'EditCargoMain', data: reqData});
  }
  cargoMainDel(data: CargoMainCUDParams) {
    return this.sendCUD('cargo/CUD', {action: 'DelCargoMain', data: {hbl: data.hbl}});
  }
  cargoMovingCalendarList(data: QueryListParams) {
    return this.sendList('cargo/ListCalendar', {action: 'Moving', data})
  }
  cargoCourierCalendarList(data: QueryListParams) {
    return this.sendList('cargo/ListCalendar', {action: 'Courier', data})
  }
  cargoECommerceCalendarList(data: QueryListParams) {
    return this.sendList('cargo/ListCalendar', {action: 'eCommerce', data})
  }
  cargoMovingListAll(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoAll', data: {...data, cargoType: 'Moving'}})
  }
  cargoCourierListAll(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoAll', data: {...data, cargoType: 'Courier'}})
  }
  cargoForwardListAll(data: QueryListParams) {
    return this.sendList('bl/ListData', {action: 'ListTradeBL', data})
  }
  cargoListAll(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoAll', data})
  }
  cargoMovingShippingList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoShip', data: {...data, cargoType: 'Moving'}})
  }
  cargoCourierShippingList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoShip', data: {...data, cargoType: 'Courier'}})
  }
  cargoMovingWorkOrderList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoWork', data})
  }
  cargoMovingContainerList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoContainer', data})
  }
  cargoECommerceContainerList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoContainer', data})
  }
  cargoMovingCustomerData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Customer', data: {hblCode}})
  }
  cargoCourierCustomerData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Customer', data: {hblCode}})
  }
  cargoMovingJobData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Job', data: {hblCode}})
  }
  cargoMovingWorkOrderData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Work', data: {hblCode}})
  }
  cargoCourierWorkOrderData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Work', data: {hblCode}})
  }
  cargoCourierConsigneeData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Consignee', data: {hblCode}})
  }
  cargoMovingShipData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Ship', data: {hblCode}})
  }
  cargoCourierShipData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Ship', data: {hblCode}})
  }
  cargoShipBoxList(shipId: number) {
    return this.sendList('cargo/ListData', {action: 'ListCargoShipBox', data: {shipId}})
  }
  cargoShipBoxItemList(shipId: number, boxNo: number) {
    return this.sendList('cargo/ListData', {action: 'ListCargoShipBoxItem', data: {shipId, boxNo, itemNo: 0}})
  }
  cargoMovingDestData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Dest', data: {hblCode}})
  }
  cargoMovingAccountData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Account', data: {hblCode}})
  }
  cargoCourierAccountData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Account', data: {hblCode}})
  }
  cargoMovingPayHistoryData(hblCode, accountId) {
    return this.sendList('cargo/ViewData', {action: 'PayHistory', data: {hblCode, accountId}})
  }
  cargoCourierPayHistoryData(hblCode, accountId) {
    return this.sendList('cargo/ViewData', {action: 'PayHistory', data: {hblCode, accountId}})
  }
  cargoMovingMessageData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'CMessage', data: {hblCode}})
  }
  cargoCourierMessageData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'CMessage', data: {hblCode}})
  }
  cargoCustomerData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Customer', data: {hblCode}})
  }
  cargoAccountData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Account', data: {hblCode}})
  }
  cargoPayHistoryData(hblCode, accountId) {
    return this.sendList('cargo/ViewData', {action: 'PayHistory', data: {hblCode, accountId}})
  }
  cargoConsigneeData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Consignee', data: {hblCode}})
  }
  cargoMessageData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'CMessage', data: {hblCode}})
  }
  cargoShipData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Ship', data: {hblCode}})
  }
  cargoWorkOrderData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'Work', data: {hblCode}})
  }
  cargoECItemData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'EcommerceItem', data: {hblCode}})
  }
  cargoWorkOrderList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoWork', data})
  }
  cargoWorkOrderAdd(data: CargoWorkOrderCUDParams) {
    const reqData = this.getCargoWorkOrderData(data);
    return this.sendCUD('cargo/CUD', {action: 'AddCargoWork', data: reqData});
  }
  cargoWorkOrderEdit(data: CargoWorkOrderCUDParams) {
    const reqData = this.getCargoWorkOrderData(data);
    return this.sendCUD('cargo/CUD', {action: 'EditCargoWork', data: reqData});
  }
  cargoWorkOrderDel(data: CargoWorkOrderCUDParams) {
    return this.sendCUD('cargo/CUD', {action: 'DelCargoWork', data: {hbl: data.hbl, id: data.id}});
  }
  cargoJobAdd(data: CargoJobCUDParams) {
    const reqData = this.getCargoJobData(data);
    return this.sendCUD('cargo/CUD', {action: 'AddCargoJob', data: reqData});
  }
  cargoJobEdit(data: CargoJobCUDParams) {
    const reqData = this.getCargoJobData(data);
    return this.sendCUD('cargo/CUD', {action: 'EditCargoJob', data: reqData});
  }
  cargoJobDel(data: CargoJobCUDParams) {
    return this.sendCUD('cargo/CUD', {action: 'DelCargoJob', data: {hbl: data.hbl, jobId: data.jobId}});
  }
  cargoShipAdd(data: CargoShipCUDParams) {
    const reqData = this.getCargoShipData(data);
    return this.sendCUD('cargo/CUD', {action: 'AddCargoShip', data: reqData});
  }
  cargoShipEdit(data: CargoShipCUDParams) {
    const reqData = this.getCargoShipData(data);
    return this.sendCUD('cargo/CUD', {action: 'EditCargoShip', data: reqData});
  }
  cargoShipDel(data: CargoShipCUDParams) {
    return this.sendCUD('cargo/CUD', {action: 'DelCargoShip', data: {hbl: data.hbl, jobId: data.jobId, id: data.id}});
  }
  cargoShipBoxAdd(data) {
    const {shipId, boxNo, grossWeight, calWeight, width, length, height, boxCBM} = data;
    const reqData = {
      shipId,
      boxNo: parseInt(boxNo),
      grossWeight: parseFloat(grossWeight),
      calWeight: parseFloat(calWeight),
      width: parseFloat(width),
      length: parseFloat(length),
      height: parseFloat(height),
      boxCBM: parseFloat(boxCBM),
    };
    return this.sendCUD('cargo/CUD', {action: 'AddCargoShipBox', data: reqData});
  }
  cargoShipBoxDel(data) {
    const {shipId, boxNo} = data;
    const reqData = {shipId, boxNo: parseInt(boxNo)};
    return this.sendCUD('cargo/CUD', {action: 'DelCargoShipBox', data: reqData});
  }
  cargoShipBoxItemAdd(data) {
    const {shipId, boxNo, itemNo, itemQty, unitPrice, itemTitle} = data;
    const reqData = {
      shipId,
      boxNo: util.toInt(boxNo),
      itemNo: util.toFloat(itemNo),
      itemQty: util.toFloat(itemQty),
      unitPrice: util.toFloat(unitPrice),
      itemTitle,
    };
    return this.sendCUD('cargo/CUD', {action: 'AddCargoShipBoxItem', data: reqData});
  }
  cargoShipBoxItemDel(data) {
    const {shipId, boxNo, itemNo, linkedId} = data;
    const reqData = {
      shipId,
      boxNo: util.toInt(boxNo),
      itemNo: util.toInt(itemNo),
      linkedId: util.toInt(linkedId),
    };
    return this.sendCUD('cargo/CUD', {action: 'DelCargoShipBoxItem', data: reqData});
  }
  cargoDestAdd(data: CargoDestCUDParams) {
    const reqData = this.getCargoDestData(data);
    return this.sendCUD('cargo/CUD', {action: 'AddCargoDest', data: reqData});
  }
  cargoDestEdit(data: CargoDestCUDParams) {
    const reqData = this.getCargoDestData(data);
    return this.sendCUD('cargo/CUD', {action: 'EditCargoDest', data: reqData});
  }
  cargoDestDel(data: CargoDestCUDParams) {
    return this.sendCUD('cargo/CUD', {action: 'DelCargoDest', data: {hbl: data.hbl, jobId: data.jobId}});
  }
  cargoAccountAdd(data: CargoAccountCUDParams) {
    const reqData = this.getCargoAccountData(data);
    return this.sendCUD('account/CUD', {action: 'AddCargoAccount', data: reqData});
  }
  cargoAccountEdit(data: CargoAccountCUDParams) {
    const reqData = this.getCargoAccountData(data);
    return this.sendCUD('account/CUD', {action: 'EditCargoAccount', data: reqData});
  }
  cargoAccountDel(data: CargoAccountCUDParams) {
    return this.sendCUD('account/CUD', {action: 'DelCargoAccount', data: {hbl: data.hbl, id: data.id}});
  }
  cargoExpenseAdd(data: CargoExpenseCUDParams) {
    const reqData = this.getCargoExpenseData(data);
    return this.sendCUD('account/CUD', {action: 'AddCraftExpense', data: reqData});
  }
  cargoExpenseEdit(data: CargoExpenseCUDParams) {
    const reqData = this.getCargoExpenseData(data);
    return this.sendCUD('account/CUD', {action: 'EditCraftExpense', data: reqData});
  }
  cargoExpenseDel(data: CargoExpenseCUDParams) {
    return this.sendCUD('account/CUD', {action: 'DelCraftExpense', data: {craftId: data.craftId, id: data.id}});
  }
  cargoPayHistoryAdd(data: CargoPayHistoryCUDParams) {
    const reqData = this.getCargoPayHistoryData(data);
    return this.sendCUD('account/CUD', {action: 'AddCargoPayHistory', data: reqData});
  }
  cargoPayHistoryEdit(data: CargoPayHistoryCUDParams) {
    const reqData = this.getCargoPayHistoryData(data);
    return this.sendCUD('account/CUD', {action: 'EditCargoPayHistory', data: reqData});
  }
  cargoPayHistoryDel(data: CargoPayHistoryCUDParams) {
    return this.sendCUD('account/CUD', {action: 'DelCargoPayHistory', data: {accountId: data.accountId, jobId: data.jobId}});
  }
  cargoMessageAdd(data: CargoMessageCUDParams) {
    const {hbl, remarkFrom, remarkTo} = data;
    const reqData = {hbl, remarkFrom, remarkTo};
    return this.sendCUD('cargo/CUD', {action: 'AddCargoMessage', data: reqData});
  }
  cargoMessageEdit(data: CargoMessageCUDParams) {
    const {hbl, remarkFrom, remarkTo} = data;
    const reqData = {hbl, remarkFrom, remarkTo};
    return this.sendCUD('cargo/CUD', {action: 'EditCargoMessage', data: reqData});
  }
  cargoMessageDel(data: CargoMessageCUDParams) {
    const {hbl} = data;
    return this.sendCUD('cargo/CUD', {action: 'DelCargoMessage', data: {hbl}});
  }
  accountCargoMovingList(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListCargoAccount', data})
  }
  accountCargoCraftExpenseList(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListCraftExpense', data})
  }
  cargoECItemAdd(data: CargoECItemCUDParams) {
    const reqData = this.getCargoECItemData(data);
    return this.sendCUD('cargo/CUD', {action: 'AddCargoEcItem', data: reqData});
  }
  cargoECItemEdit(data: CargoECItemCUDParams) {
    const reqData = this.getCargoECItemData(data);
    return this.sendCUD('cargo/CUD', {action: 'EditCargoEcItem', data: reqData});
  }
  cargoECItemDel(data: CargoECItemCUDParams) {
    const reqData = this.getCargoECItemData(data);
    return this.sendCUD('cargo/CUD', {action: 'DelCargoEcItem', data: reqData});
  }
  cargoShipBoxItemBulkAdd(data) {
    return this.sendCUD('cargo/CUD', {action: 'AddCShipItemBulk', data});
  }
  cargoCheckWorkAssign(cargoType, workDate, vehicleId, workTime) {
    return this.sendList('cargo/ViewData', {action: 'CheckWorkAssign', data: {cargoType, workDate, vehicleId, workTime}});
  }
  cargoWorkAssignList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCargoWorkAssign', data})
  }
  cargoWorkTimeAndAddressEdit({workTime, address, workIds}) {
    return this.sendCUD('cargo/CUD', {action: 'EditCargoWorkTnA', data: {workTime, address, workIds}});
  }
  cargoWorkAssignAdd(data: CargoWorkAssignCUDParams) {
    const reqData = this.getWorkAssignData(data);
    return this.sendCUD('cargo/CUD', {action: 'AddCargoWorkAssign', data: reqData});
  }
  cargoWorkAssignDel(data: CargoWorkAssignCUDParams) {
    const reqData = this.getWorkAssignData(data);
    return this.sendCUD('cargo/CUD', {action: 'DelCargoWorkAssign', data: reqData});
  }
  cargoWorkAssignedList(data: QueryListParams) {
    return this.sendList('cargo/ListData', {action: 'ListCWorkAssigned', data})
  }
  cargoAccountChangeCommission(accountId, amount) {
    return this.sendCUD('account/CUD', {action: 'ChangeCommission', data: {accountId, amount}});
  }
  cargoTrackingData(hblCode) {
    return this.sendList('cargo/ViewData', {action: 'CargoTracking', data: {hblCode}})
  }
  forwardTradePartnerList(data: QueryListParams) {
    return this.sendList('partner/ListData', {action: 'ListTPartner', data});
  }
  forwardTradePartnerView(id: number) {
    return this.sendList('partner/ViewData', {action: 'PartnerData', data: {id}});
  }
  forwardTradePartnerHistoryList(data: QueryListParams) {
    return this.sendList('partner/ListData', {action: 'ListBLHistory', data});
  }
  forwardTradePartnerAdd(data: ForwardTradePartnerCUDParams) {
    const reqData = this.getForwardTradePartnerData(data);
    return this.sendCUD('partner/CUD', {action: 'AddTPartner', data: reqData});
  }
  forwardTradePartnerEdit(data: ForwardTradePartnerCUDParams) {
    const reqData = this.getForwardTradePartnerData(data);
    return this.sendCUD('partner/CUD', {action: 'EditTPartner', data: reqData});
  }
  forwardTradePartnerContactList(id) {
    return this.sendList('partner/ListData', {action: 'ListTPartnerStaff', data: {id}});
  }
  forwardTradePartnerContactAdd(data) {
    const {id, jobId, division, sname, phone, cell, fax, email, isrep, remark} = data;
    const reqData = {id, jobId, division, sname, phone, cell, fax, email, isrep, remark};
    return this.sendCUD('partner/CUD', {action: 'AddEditTPartnerStaff', data: reqData});
  }
  forwardTradePartnerContactDel(data) {
    const {id, jobId} = data;
    const reqData = {id, jobId};
    return this.sendCUD('partner/CUD', {action: 'DelTPartnerStaff', data: reqData});
  }
  forwardTradePartnerBankList(id) {
    return this.sendList('partner/ListData', {action: 'ListTPartnerBank', data: {id}});
  }
  forwardTradePartnerBankAdd(data) {
    const {id, jobId, bname, accountNo, holder, remark, memo} = data;
    const reqData = {id, jobId, bname, accountNo, holder, remark, memo};
    return this.sendCUD('partner/CUD', {action: 'AddEditTPartnerBank', data: reqData});
  }
  forwardTradePartnerBankDel(data) {
    const {id, jobId} = data;
    const reqData = {id, jobId};
    return this.sendCUD('partner/CUD', {action: 'DelTPartnerBank', data: reqData});
  }
  async getMainMasterId(blNo: number) {
    return this.sendList('bl/GetData', {action: 'GetMainMasterId', data: {blNo}});
  }
  forwardAddEditBLEntry(data: ForwardBLEntryParams) {
    return this.sendCUD('bl/CUD', {action: 'AddEditBLEntry', data});
  }
  forwardDelBLEntry(id: number) {
    return this.sendCUD('bl/CUD', {action: 'DelBLEntry', data: {id}});
  }
  forwardViewBLEntry(data) {
    return this.sendList('bl/ViewData', {action: 'TradeBLData', data})
  }
  forwardViewBLCopyEntry(data) {
    return this.sendList('bl/ViewData', {action: 'TradeBLCopyData', data})
  }
  async setBookConfirm(data: {id: number, jbookConfirm: Object}) {
    return this.sendCUD('bl/CUD', {action: 'SetBookConfirm', data});
  }
  async forwardSetBLMark(data: {id: number, jmark: Object}) {
    return this.sendCUD('bl/CUD', {action: 'SetBLMark', data});
  }
  async forwardViewBLMark(id) {
    return this.sendList('bl/ViewData', {action: 'TradeBLMarkData', data: {id}});
  }
  async workOrderBLListData(workId) {
    return this.sendList('bl/ListData', {action: 'ListWorkOrderBL', data: {workId}});
  }
  async craftContainerItemList(data) {
    const {id} = data;
    const dataToSend = {containerId: id};
    return this.sendList('craft/ListData', {action: 'ListContainerItems', data: dataToSend});
  }
  async delCraft(id) {
    return this.sendCUD('craft/CUD', {action: 'DelCraft', data: {id}});
  }
  async delContainer(id) {
    return this.sendCUD('craft/CUD', {action: 'DelContainer', data: {id}});
  }
  async craftSet(data) {
    const {id, isVessel, carrierId, craftNo, vslName, etdDate, etdTime, etaDate, etaTime, departure, destination, jextra} = data;
    const etdTs = etdTime ? util.toTS(`${etdDate} ${etdTime}:00`) : util.toTS(`${etdDate}`);
    const etaTs = etaTime ? util.toTS(`${etaDate} ${etaTime}:00`) : util.toTS(`${etaDate}`);
    const dataToSend = {id, isVessel, carrierId, craftNo, vslName, etdDate: etdTs, etaDate: etaTs, departure, destination, jextra};
    return this.sendCUD('craft/CUD', {action: 'SetCraft', data: dataToSend});
  }
  async craftListFlight(data: QueryListParams) {
    return this.sendList('craft/ListData', {action: 'ListCraftFlight', data});
  }
  async craftListVessel(data: QueryListParams) {
    return this.sendList('craft/ListData', {action: 'ListCraftVessel', data});
  }
  async craftContainerSet(data) {
    const {id, containerNo, tpSize, filingNo, sealNo, packCount, packUnit, weightKg, cbm, remark, pickNo, jextra} = data;
    const dataToSend = {id, containerNo, tpSize, filingNo, sealNo, packCount, packUnit, weightKg, cbm, remark, pickNo, jextra};
    return this.sendCUD('craft/CUD', {action: 'SetCraftContainer', data: dataToSend});
  }
  async craftContainerList(data: QueryListParams) {
    return this.sendList('craft/ListData', {action: 'ListContainer', data});
  }
  async craftContainerHBLSet(data) {
    const {containerId, blId, packCount, packUnit, weightKg, cbm, seqNo = 0} = data;
    const dataToSend = {
      containerId, blId,
      packCount: util.toInt(packCount),
      packUnit,
      weightKg: util.toFloat(weightKg),
      cbm: util.toFloat(cbm),
      seqNo: util.toInt(seqNo),
    };
    return this.sendCUD('craft/CUD', {action: 'SetCraftContainerHBL', data: dataToSend});
  }
  async craftContainerHBLDel(data) {
    const {containerId, blId} = data;
    const dataToSend = {containerId, blId};
    return this.sendCUD('craft/CUD', {action: 'DelCraftContainerHBL', data: dataToSend});
  }
  async craftContainerHBLList(filingNo, containerNo) {
    // return this.sendList('craft/ListData', {action: 'ListContainerHBL', data: {containerId}});
    return this.sendList('craft/ListData', {action: 'ListContainerHBL', data: {filingNo, containerNo}});
  }
  async listCraftHBL(id) {
    return this.sendList('craft/ListData', {action: 'ListCraftHBL', data: {id}});
  }
  async delContainerHBL(blId, containerNo) {
    return this.sendCUD('craft/CUD', {action: 'DelContainerHBL', data: {blId, containerNo}})
  }
  async craftLinkHBL(filingNo, containerNo, houses) {
    return this.sendCUD('craft/CUD', {action: 'LinkContainerHBL', data: {filingNo, containerNo, houses}})
  }
  async setSeqNo(blId, seqNo) {
    return this.sendCUD('craft/CUD', {action: 'SetSeqNo', data: {blId, seqNo}});
  }
  async sendList(path, reqData, doNotShowError) {
    const res = await this.send(path, reqData, doNotShowError);
    return res ?? {data: null, totalCount: 0};
  }
  async setAssignTime(assignId, woIds, timeStr) {
    return this.sendCUD('bl/CUD', {action: 'SetAssignTime', data: {assignId, woIds: woIds, timeStr: timeStr}});
  }
  listBLReport(data: QueryListParams) {
    return this.sendList('bl/ListData', {action: 'ListBLReport', data})
  }
  async setComment(blId, comment) {
    return this.sendCUD('bl/CUD', {action: 'SetComment', data: {blId, comment}});
  }
  async getInvSpaces(data) {
    return this.sendList('bl/GetData', {action: 'GetInvSpaces', data});
  }
  useInventoryOptions(branchId = 0, onlyEmpty = true, blId) {
    const [options, setOptions] = React.useState([]);
    const isAll = onlyEmpty ? !!branchId : true;
    React.useEffect(() => {
      if (isAll) {
        const data = onlyEmpty ? {onlyEmpty, blId} : {branchId, onlyEmpty, blId};
        this.getInvSpaces(data).then(({data}) => {
          const newOptions = data[0].retval.map(([value, label]) => ({value, label}));
          setOptions(newOptions);
        }).catch();
      }
    }, [branchId, blId]);
    return options;
  }
  async sendCUD(path, reqData, doNotShowError) {
    const res = await this.send(path, reqData, doNotShowError);
    if (res?.data?.[0].ret === true) {
      return true;
    } else if (res?.data?.[0]) {
      return res.data[0];
    } else {
      return false;
    }
  }
  async send(path, reqData, doNotShowError) {
    try {
      const res = await axios.request({
        url: `${API_URL}/${path}`,
        method: 'POST',
        data: reqData,
        headers: {
          Authorization: `bearer ${this.token ?? localStorage.getItem('@token')}`,
        }
      });
      // process.env.NODE_ENV !== 'production' && console.log('[API]', path, reqData, res);
      useDev && console.log('[API]', path, reqData, res);
      const {err_message: message, data, totalcount: totalCount = 0} = res.data;
      if (!message) {
        return {data, totalCount};
      } else {
        const msg = message.toLowerCase();
        if (msg === 'login') {
          this.history !== undefined ? this.history.push('/admin/login') : util.sendLoginPage()
        } else if (msg.startsWith('error')) {
          if (doNotShowError !== true) {
            const messages = msg.split('#');
            if (messages.length === 2) {
              showDialog(`Something went wrong! (${messages[1]})`, 'System Error', 'danger')
            } else {
              if (message) {
                showDialog(message, 'Something went wrong!', 'danger')
              } else {
                showDialog('Something went wrong!', 'System Error', 'danger')
              }
            }
          }
        } else {
          if (doNotShowError !== true) {
            showDialog(message, 'Something went wrong!', 'danger')
          }
        }
      }
    } catch (error) {
      if (error?.isAxiosError && error?.response?.status === 401) {
        this.history !== undefined ? this.history.push('/admin/login') : util.sendLoginPage()
      } else {
        if (doNotShowError !== true) {
          showDialog('System Error!', 'System Error', 'danger')
        }
      }
    }
  }
  async sendBase64(path, reqData, fileName, doNotShowError) {
    try {
      const res = await axios.request({
        url: `${API_URL}/${path}`,
        method: 'POST',
        data: reqData,
        headers: {
          ContentType: 'plain/text',
          Authorization: `bearer ${this.token ?? localStorage.getItem('@token')}`,
          // 'X-EXCEL_CSV': fileName,
        }
      });
      // process.env.NODE_ENV !== 'production' && console.log('[API]', path, reqData, res);
      // useDev && console.log('[API]', path, reqData, res);
      const {err_message: message, data, totalcount: totalCount = 0} = res.data;
      if (!message) {
        return {data, totalCount};
      } else {
        const msg = message.toLowerCase();
        if (msg === 'login') {
          this.history.push('/admin/login');
        } else if (msg.startsWith('error')) {
          if (doNotShowError !== true) {
            const messages = msg.split('#');
            if (messages.length === 2) {
              showDialog(`Something went wrong! (${messages[1]})`, 'System Error', 'danger')
            } else {
              if (message) {
                showDialog(message, 'Something went wrong!', 'danger')
              } else {
                showDialog('Something went wrong!2', 'System Error', 'danger')
              }
            }
          }
        } else {
          if (doNotShowError !== true) {
            showDialog(message, 'System Error', 'danger')
          }
        }
      }
    } catch (error) {
      if (error?.isAxiosError && error?.response?.status === 401) {
        this.history.push('/admin/login');
      } else {
        if (doNotShowError !== true) {
          showDialog('Something went wrong!3', 'System Error', 'danger')
        }
      }
    }
  }
  toTS(dateString) {
    return dateString ? moment(dateString).toDate().getTime() : 0;
  }
  getStaffData(data: StaffCUDParams): StaffCUDParams {
    let {
      userId = 0,
      loginId,
      password,
      userName,
      userName2,
      email,
      cphone,
      hphone,
      areaId,
      empDate,
      workEnd,
      startDate,
      endDate = 0,
      jobTitle,
      auth,
      isActive = false,
      teamMain,
      address,
    } = data;
    if (typeof empDate === 'string' || endDate === undefined) {
      empDate = this.toTS(empDate);
    }
    if (typeof workEnd === 'string' || endDate === undefined) {
      workEnd = this.toTS(workEnd);
    }
    if (typeof startDate === 'string' || endDate === undefined) {
      startDate = this.toTS(startDate);
    }
    if (typeof endDate === 'string' || endDate === undefined) {
      endDate = this.toTS(endDate);
    }
    areaId = parseInt(areaId);
    auth = parseInt(auth);
    return {
      userId, loginId, password, userName, userName2, email,
      cphone, hphone, areaId,
      empDate, workEnd, startDate, endDate, jobTitle, auth, isActive,teamMain, address,
    };
  }
  getCustomerData(data: CustomerCUDParams): CustomerCUDParams {
    let {
      customerId,
      password,
      customerName,
      customerName2,
      email,
      cphone,
      hphone,
      address,
      city,
      customsCode,
      areaId,
      rate,
      isLogin = false,
      remark,
      postalCode,
      cType,
      pickupDay,
      pickupTime,
    } = data;
    areaId = parseInt(areaId);
    rate = parseFloat(rate);
    return {
      customerId, password, customerName, customerName2, email,
      cphone, hphone, address, city, customsCode, areaId,
      rate, isLogin, remark, postalCode, cType, pickupDay, pickupTime,
    };
  }
  getStaffScheduleData(data: StaffScheduleCUDParams) {
    const {  id,
      userId,
      isHoliday,
      planDate,
      planDate2,
      planTime,
      location,
      purpose,
      jobDesc,
      dayCount,
    } = data;
    return {
      id,
      userId,
      isHoliday,
      planDate: planDate instanceof Date ? planDate.getTime() : planDate,
      planDate2: planDate2 instanceof Date ? planDate2.getTime() : planDate2,
      planTime,
      location,
      purpose,
      jobDesc,
      dayCount: dayCount ? parseInt(dayCount) : undefined,
    };
  }
  getStaffHolidayData(data: StaffHolidayCUDParams) {
    const {id, userId, startDate, endDate, totalDays, usedDays, remark} = data;
    return {
      id, userId,
      startDate: startDate instanceof Date ? startDate.getTime() : startDate,
      endDate: endDate instanceof Date ? endDate.getTime() : endDate,
      totalDays,
      usedDays: (usedDays || usedDays === 0) ? parseFloat(usedDays) : undefined,
      remark,
    };
  }
  getCargoMainData(data: CargoMainCUDParams) {
    const {
      hbl,
      customerId,
      cargoType,
      subType,
      departure,
      entryName,
      visaType,
      staffId,
      partnerId,
      tracking,
      tCompany,
      eteDate,
      extraInfo,
      vinInfo,
      cphone,
      hphone,
      city,
      address,
      email,
      realName,
      engName,
      pickupDate,
      pickupTime,
      deskType,
      isAir,
      totalPrice,
      currency,
    } = data;
    return {
      hbl,
      customerId : util.toInt(customerId),
      cargoType,
      subType,
      departure: util.toInt(departure),
      entryName: entryName ? entryName : engName,
      visaType,
      staffId: util.toInt(staffId),
      partnerId: util.toInt(partnerId),
      tracking,
      tCompany,
      eteDate: util.toTS(eteDate),
      extraInfo,
      vinInfo,
      cphone,
      hphone,
      city,
      address,
      email,
      realName,
      engName,
      pickupDate: util.toTS(pickupDate),
      pickupTime,
      deskType,
      isAir: isAir === 'A',
      totalPrice: util.toFloat(totalPrice),
      currency,
    };
  }
  getCargoWorkOrderData(data: CargoWorkOrderCUDParams) {
    const {
      id,
      hbl,
      branchId,
      workType,
      workDate,
      city,
      address,
      workers,
      phones,
      workTime,
      remark,
      cbm,
      tapeColor,
      postal,
      engAddress,
      customs,
      iscleared,
      props,
      deskType,
      customs_code,
      email,
      consigneeName,
      consigneeNameEng,
    } = data;
    return {
      id,
      hbl,
      branchId: util.toInt(branchId),
      workType,
      workDate: util.toTS(workDate),
      city,
      address,
      workers,
      phones,
      workTime,
      remark,
      cbm: cbm ? util.toFloat(cbm) : undefined,
      tapeColor,
      postal,
      engAddress,
      customs,
      iscleared: iscleared === 'Y',
      props,
      deskType,
      customs_code,
      email,
      consigneeName,
      consigneeNameEng,
    };
  }
  getCargoJobData(data: CargoJobCUDParams) {
    const {hbl, jobId, jobDate, city, address, jobTime, workers, reqDate, props} = data;
    return {
      hbl,
      jobId: parseInt(jobId),
      jobDate: util.toTS(jobDate),
      city,
      address,
      jobTime,
      workers,
      reqDate: util.toTS(reqDate),
      props,
    };
  }
  getCargoShipData(data: CargoShipCUDParams) {
    const {hbl, id, cmId, ccId, jobId, cbm1, cbm2, weight, pcount, coverAmt, coverType, fileNo, seqNo, chargeWt, wlhStr} = data;
    return {
      hbl,
      id: id ? parseInt(id) : 0,
      cmId: cmId ? parseInt(cmId) : 0,
      ccId: ccId ? parseInt(ccId) : 0,
      jobId,
      cbm1: cbm1 ? parseFloat(cbm1) : undefined,
      cbm2: cbm2 ? parseFloat(cbm2) : undefined,
      weight: weight ? parseFloat(weight) : undefined,
      pcount: pcount ? parseInt(pcount) : undefined,
      coverAmt: coverAmt ? parseFloat(coverAmt) : undefined,
      coverType,
      fileNo,
      seqNo: seqNo ? parseInt(seqNo) : undefined,
      chargeWt: chargeWt ? parseFloat(chargeWt) : undefined,
      wlhStr,
    };
  }
  getCargoDestData(data: CargoDestCUDParams) {
    const {
      hbl,
      jobId,
      city,
      address,
      engAddress,
      postal,
      hphone,
      cphone,
      ddate,
      dtime,
      branchId,
      customs,
      isCleared,
    } = data;
    return {
      hbl,
      jobId,
      city,
      address,
      engAddress,
      postal,
      hphone,
      cphone,
      ddate: util.toTS(ddate),
      dtime,
      branchId,
      customs,
      isCleared: isCleared ? isCleared === 'Y' : undefined,
    };
  }
  getCargoAccountData(data: CargoAccountCUDParams) {
    const {hbl, id, currency, price, atypeId, branchId, remark} = data;
    return {
      hbl,
      id,
      currency,
      price: util.toFloat(price),
      atypeId: util.toInt(atypeId),
      branchId: util.toInt(branchId),
      remark
    };
  }
  getCargoExpenseData(data: CargoExpenseCUDParams) {
    const {craftId, id, currency, price, atypeId, branchId, remark} = data;
    return {
      craftId,
      id,
      currency,
      price: util.toFloat(price),
      atypeId: util.toInt(atypeId),
      branchId: util.toInt(branchId),
      remark
    };
  }
  getCargoPayHistoryData(data: CargoPayHistoryCUDParams) {
    const {accountId, jobId, payAmount, payDate, payType, remark, currency, rate} = data;
    return {
      accountId,
      jobId,
      payAmount: util.toFloat(payAmount),
      payDate: util.toTS(payDate),
      payType,
      currency,
      rate: util.toFloat(rate),
      remark,
    };
  }
  getAirVesselData(data: AirVesselCUDParams) {
    const {id, craftCode, craftName, isAir, etdDate, etaDate, pod, pol} = data;
    return {
      id,
      craftCode,
      craftName,
      isAir,
      etdDate: util.toTS(etdDate),
      etaDate: util.toTS(etaDate),
      pod: util.toInt(pod),
      pol: util.toInt(pol),
      // freightFee,
      // currency,
    };
  }
  getContainerData(data: ContainerCUDParams) {
    const {id, pId, fileNo, containerId, craftId, weight, sealNo} = data;
    return {
      id, pId, fileNo, containerId, craftId,
      weight: util.toFloat(weight),
      sealNo,
      // mainFee: util.toFloat(mainFee),
      // currency,
      // extraFee: util.toFloat(extraFee),
      // currency2
    };
  }
  getCargoECItemData(data: CargoECItemCUDParams) {
    const {hbl, id, accountId, cbm, currency, inDate, itemName, last4, payType, price, remark, requestDate, tracking, weight} = data;
    return {
      hbl,
      id,
      accountId,
      cbm: util.toFloat(cbm),
      currency,
      inDate: util.toTS(inDate),
      itemName,
      last4,
      payType,
      price: util.toFloat(price),
      remark,
      requestDate: util.toTS(requestDate),
      tracking,
      weight: util.toFloat(weight),
    };
  }
  getWorkAssignData(data: CargoWorkAssignCUDParams) {
    const {id, assignList, cargoType, remark, staffId, vehicleId, workDate, workTime} = data;
    return {
      id, assignList, cargoType, remark, workTime,
      staffId: util.toInt(staffId),
      vehicleId: util.toInt(vehicleId),
      workDate: util.toTS(workDate),
    };
  }
  getForwardTradePartnerData(data: ForwardTradePartnerCUDParams) {
    const {
      id,
      partnerType,
      branchId,
      staffId,
      importNo,
      engName,
      localName,
      country,
      city,
      postalCode,
      alias,
      province,
      localAddress,
      billAddress,
      blName,
      ceo,
      idNo,
      taxidNo,
      corpNo,
      accGroupId,
      iata,
      shipperId,
      prefix,
      scac,
      cbsaScac,
      kams,
      isActive,
      phone,
      cell,
      fax,
      email,
      remark,
      jobId,
      discountRate,
      pwd,
      pickupDay,
      pickupTime,
      vendorId,
      labelPrint,
      hscode,
      labelType,
    } = data;
    return {
      id,
      partnerType,
      branchId: util.toInt(branchId),
      staffId: util.toInt(staffId),
      importNo,
      engName,
      localName,
      country,
      city,
      postalCode,
      alias,
      province,
      localAddress,
      billAddress,
      blName,
      ceo,
      idNo,
      taxidNo,
      corpNo,
      accGroupId,
      iata,
      shipperId,
      prefix,
      scac,
      cbsaScac,
      kams,
      isActive,
      phone,
      cell,
      fax,
      email,
      remark,
      jobId,
      discountRate: util.toFloat(discountRate),
      pwd,
      pickupDay,
      pickupTime,
      vendorId,
      labelPrint,
      hscode,
      labelType,
    };
  }
  getQueryListData(data: QueryListParams): QueryListParams {
    const {
      page,
      rowCount,
      qryText, // ListContainer 시 여기에 craftId 넣어줌
      orderBy,
      isDesc,
      fromDate,
      toDate,
      cType, // Customer 전용
      cargoType, // Cargo 전용
      subType, // Cargo 전용
      hblCode, // Cargo 전용
      customerId, // Cargo 전용
      partnerId, // Cargo 전용
      accountType, // Cargo 전용
      currency, // Cargo 전용
      departureId, // Cargo 전용
      destinationId, // Cargo 전용
      onlyVehicle, // Cargo 전용
      fileNo, // Cargo 전용
      masterNo, // Cargo 전용
      isAir, // Cargo 전용
      branchId, // Account/Moving 전용
      workType,
      craftId,
      poName,
      city,
      tpSize, // Container 전용
      searchName, // Container 전용
      searchVal, // Container 전용
      vslVoy, // Container 전용
      pol, // Container 전용
      pod, // Container 전용
      etd, // Container 전용
      eta, // Container 전용
      invType, // Account 전용
      invNo, // Account 전용
      blNo, // Account 전용
      billToId, // Account 전용
      atypeId, // Account 전용
      unpaid, // Account 전용
      isOverdue, // Account 전용
      filingNo,  // Account 전용
      staffId,  // Trade Partner 전용
      partnerType,  // Trade Partner 전용
      isAll,  // Account 전용
      kind, // BL List 전용
      carrierType, // BL List 전용
      bound, // BL List 전용
      HM, // BL List 전용
      shipperConsignee, // BL List 전용
      trackingNo, // BL List 전용
      flightDate, // BL List 전용
      isRequest, // Account 전용
      onlyWaitList, // BL List 전용
      notLinkedAccount, // BL List 전용
      bankInfo, // Account 전용
      payType,  // Account 전용
      remark, // Account 전용
      type, // Request 전용
      status, // Request 전용
      isActive, // Partner 전용
      flag, // BL List 전용
      storage, // BL List 전용
      customer_only, // BL List 전용
      teamMain, // Account 전용
      amount, // Account 전용
      kindArr,  // Schedule 전용
      paidRequest, // Request 전용
      vendorInv,  // Request 전용
      statusNo, // BL List 전용
      spaceId, // BL List 전용
      departure,    // moving
      destination,  // moving
      bl_status,  // forwarding
      paidStatus,  // account > profit
    } = data;
    return {
      page: page ?? 1,
      rowCount: rowCount ?? 20,
      qryText, // ListContainer 시 여기에 craftId 넣어줌
      orderBy,
      isDesc,
      fromDate,
      toDate,
      cType, // Customer 전용
      cargoType, // Cargo 전용
      subType, // Cargo 전용
      hblCode, // Cargo 전용
      customerId: util.toInt(customerId), // Cargo 전용
      partnerId: util.toInt(partnerId), // Cargo 전용
      accountType, // Cargo 전용
      currency, // Cargo 전용
      departureId: util.toInt(departureId), // Cargo 전용
      destinationId: util.toInt(destinationId), // Cargo 전용
      onlyVehicle, // Cargo 전용
      fileNo, // Cargo 전용
      masterNo, // Cargo 전용
      isAir, // Cargo 전용
      branchId: util.toInt(branchId),
      workType,
      craftId,
      poName,
      city,
      tpSize, // Container 전용
      searchName, // Container 전용
      searchVal, // Container 전용
      vslVoy, // Container 전용
      pol, // Container 전용
      pod, // Container 전용
      etd: util.toTS(etd), // Container 전용
      eta: util.toTS(eta), // Container 전용
      invType, // Account 전용
      invNo, // Account 전용
      blNo, // Account 전용
      billToId: util.toInt(billToId), // Account 전용
      atypeId: util.toInt(atypeId), // Account 전용
      unpaid: unpaid ? unpaid : undefined, // Account 전용
      isOverdue, // Account 전용
      filingNo,  // Account 전용
      staffId,  // Trade Partner 전용
      partnerType,  // Trade Partner 전용
      isAll,  // Account 전용
      kind, // BL List 전용
      carrierType, // BL List 전용
      bound, // BL List 전용
      HM, // BL List 전용
      shipperConsignee, // BL List 전용
      trackingNo, // BL List 전용
      flightDate, // BL Manifest 전용
      isRequest, // Account 전용
      onlyWaitList, // BL List 전용
      notLinkedAccount, // BL List 전용
      bankInfo, // Account 전용
      payType,  // Account 전용
      remark, // Account 전용
      type, // Request 전용
      status, // Request 전용
      isActive, // Partner 전용
      flag, // BL List 전용
      storage, // BL List 전용
      customer_only, // BL List 전용
      teamMain, // Account 전용
      amount, // Account 전용
      kindArr,  // Schedule 전용
      paidRequest, // Request 전용
      vendorInv, // Request 전용
      statusNo, // BL List 전용
      spaceId, // BL List 전용
      departure,    // moving
      destination,  // moving
      bl_status,  // forwarding
      paidStatus, // Account 전용
    };
  }
}

export default API;
