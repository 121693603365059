/**
 * @flow
 */
import React from 'react';
import useAccountListGridView from "../../grids/newCoship/useAccountListGridView";
import {useSelector} from "react-redux";
import useAccountPaymentModal from "../../modals/account/useAccountPaymentModal";

interface HouseBLAccountListProps {
  isShowList: boolean;
  branchList: any;
  onSearch: any;
}

const HouseBLAccountList = (props: HouseBLAccountListProps) => {
  const {isShowList, onSearch, branchList} = props;
  const {grid, accountPaymentModal} = useHouseBLAccountList(isShowList, onSearch, branchList);

  return (
    <div>
      {isShowList && grid.render()}
      {accountPaymentModal.render()}
    </div>
  );
};

function useHouseBLAccountList(isShowList, onSearch, branchList) {
  const {accountList} = useSelector(state => state['newCoship']);
  const accountPaymentModal = useAccountPaymentModal(onSearch);
  const onAction = ((action, data) => {
    if (action === 'commission') {
      const {account_id, inv_no: invoiceNo} = data;
      accountPaymentModal.open(account_id ?? 0, invoiceNo ?? '');
    }
  });
  // const grid = useAccountListGridView((action, data) => {
  //   if (action === 'commission') {
  //     const {account_id, inv_no: invoiceNo} = data;
  //     accountPaymentModal.open(account_id ?? 0, invoiceNo ?? '');
  //   }
  // });
  const grid = useAccountListGridView(onAction, branchList);

  React.useEffect(() => {
    isShowList && grid.setRows(accountList);
  }, [isShowList, accountList]);

  return {grid, accountPaymentModal};
}

export default HouseBLAccountList;
