/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../components/SimpleModal';
import NoticeFormManager from "../../forms/newCoship/NoticeFormManager";
import {util} from "../../services/service";
// import {util} from "../../services/service";
// import {apiForwarding} from "../../services/newCoship/service";
// import {useUser} from "../../redux/reducers/userReducer";

// export function useNoticeListModal(onSearch, kind) {
export function useNoticeListModal() {

  const [dataNoti, setDataNoti] = React.useState(undefined);
  // const user = useUser();
  // const newApi = apiForwarding;
  const editForm = new NoticeFormManager(dataNoti);

  // const onClose = () => {
  //   modal.close();
  //   util.nav(`/admin/dashboard/notice`);
  // };
  //
  // const getButtons = () => {
  //     return [
  //       {label: 'Close', color: 'primary', onClick: onClose},
  //     ];
  // };

  const modal = useSimpleModal({
    title: `Notice`,
    centered: true,
    width: 900,
    // buttons: getButtons(),
    children: editForm.renderForm(),
  });

  const open = (data) => {
    setDataNoti(data);
    // editForm.setData(data);
    modal.open();
    util.nav(`/admin/dashboard/notice`);
  };

  return {...modal, open};
}
