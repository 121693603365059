// @ts-nocheck
import axios from 'axios';
import {showDialog} from "../../redux/reducers/globalDialogReducer";
import {util} from "../service";

export interface APIResType<DataType> {
  data: DataType;
  totalCount: number;
  message: string;
}

const useDev = window.location.host === 'localhost:3000' || window.location.host === 'coship.homekling.com';
const API_URL_DEV = 'http://coship.homekling.com';
// const API_URL_DEV = 'https://bms.coship.ca';
// const API_URL_PRD = useDev ? API_URL_DEV : 'https://adm.coship.ca';
const API_URL_PRD = useDev ? API_URL_DEV : 'https://bms.coship.ca';

const API_URL = process.env.NODE_ENV === 'production' ? API_URL_PRD : API_URL_DEV;

class API {
  static async send(path, reqData, doNotShowError) {
    try {
      const res = await axios.request({
        url: `${API_URL}/rapi/${path}`,
        method: 'POST',
        data: reqData.data ? reqData : {...reqData, data: {}},
      });
      process.env.NODE_ENV !== 'production' && console.log('[API]', path, reqData, res);
      const {err_message: message, data, totalcount: totalCount = 0} = res.data;
      if (!message) {
        return {data, totalCount};
      } else {
        const msg = message.toLowerCase();
        if (msg === 'login') {
          this.history !== undefined ? this.history.push('/admin/login') : util.sendLoginPage()
        } else if (msg.startsWith('error')) {
          if (doNotShowError !== true) {
            const messages = msg.split('#');
            if (messages.length === 2) {
              showDialog(`Something went wrong! (${messages[1]})`, 'System Error', 'danger')
            } else {
              if (message) {
                showDialog(message, 'Something went wrong!', 'danger')
              } else {
                showDialog('Something went wrong!', 'System Error', 'danger')
              }
            }
          }
        } else {
          if (doNotShowError !== true) {
            showDialog(message, 'Something went wrong!', 'danger')
          }
        }
      }
    } catch (error) {
      if (error?.isAxiosError && error?.response?.status === 401) {
        this.history !== undefined ? this.history.push('/admin/login') : util.sendLoginPage()
      } else {
        if (doNotShowError !== true) {
          showDialog('System Error!', 'System Error', 'danger')
        }
      }
    }
  }
}

export default API;
