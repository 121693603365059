/**
 * @flow
 */
import React from 'react';
import {Modal} from 'reactstrap';
import {ColDef} from 'ag-grid-community';
import type {FormField} from './Form';
import DataGrid, {ActionCell, queryGridData} from './DataGrid';
import type {DataGridActionType, DataGridProps, DataGridQuery} from './DataGrid';
import Form from './Form';
import {api, util} from '../services/service';
import {useConfirm} from './Alert';
import {useTopbar} from '../redux/reducers/topbarReducer';
import type {DataGridInfo} from '../redux/reducers/dataGridReducer';
import {updateDataGridInfo} from '../redux/reducers/dataGridReducer';
import ModalDataGrid from "./ModalDataGrid";
import {useAsyncStatusModal} from './AsyncStatusModal';

export type DataGridViewAction = (action: DataGridActionType | 'addOpen' | 'editOpen' | 'copyOpen' | 'modalClose', data: Object) => Promise<any>;

export interface ModalState {
  open: boolean;
  mode?: 'add' | 'edit' | 'copy';
  data?: Object;
}

export interface DataGridViewProps {
  name: string;
  label: string;
  sortCol: string;
  sortDesc: boolean;
  pageRows: number;
  addLabel: string;
  editLabel: string;
  categoryLabel: string;
  menuLabel: string;
  columns: (ColDef | FormField)[] | (state: ModalState) => (ColDef | FormField)[];
  actions: DataGridActionType[] | () => DataGridActionType[];
  actionTooltips?: {[key: DataGridActionType]: string},
  actionWidth: number;
  nameMap?: Object;
  fields?: FormField[] | (state: ModalState) => FormField[];
  dateFields?: string[];
  renderForm?: (formik: any, fields: Array, errors: Array) => React$Node;
  showDateSearch: boolean;
  onAction: DataGridViewAction;
  onQuery: DataGridQuery,
  modalWidth?: number,
  isActionDisabled?: (type: DataGridActionType, data: Object) => boolean;
  useExtendedColDef?: boolean;
  onFormik?: (formik: any) => void;
  showDeleteButton?: boolean;
  showCopyButton?: boolean;
  onRowClick?: (data: Object, rowIndex: number, param: Object) => void;
  onCellClick?: (event: Object) => void;
  onCellValueChange?: (e: any) => void;
  hideSearchInput?: boolean;
  sortableColumns?: Object;
  useExternalForm?: boolean; // if true, onAction('add' | 'edit'); will be called when click add button or edit icon
  initialSearchToday?: boolean;
  initialSearchOneWeek?: boolean;
  initialSearchOneMonth?: boolean;
  initialSearchOneYear?: boolean;
  initialSearchLastMonth?: boolean;
  initialSearchCurrentYear?: boolean;
  initialSearchLastMonthToToday?: boolean;
  initialSearchDateNone?: boolean;
  useModalDataGrid?: boolean;
  dataGridHeight?: number;
  modalDataGridHeight?: number;
  showRowSearch?: boolean;
  doNotShowErrorMessage?: boolean;
  onRowDoubleClick?: (data: Object, rowIndex: number, param: Object) => void;
  padding?: number;
  doNotPerformInitialQuery?: boolean;
  onGridReady?: any;
  rowSelection?: string,
  rowMultiSelectWithClick?: boolean,
  onSelectionChanged?: (e: any) => void;
  isStaffEdit?: boolean;
  qryText?: string;
}

const DataGridView = (props: DataGridViewProps) => {
  const [modal, setModal] = React.useState<ModalState>({open: false});
  const [title, setTitle] = React.useState(props.editLabel);
  const confirm = useConfirm();
  useTopbar(props.categoryLabel, props.menuLabel);
  const onEditClick = (mode, data) => {
    if (props.useExternalForm === true) {
      props.onAction(mode, data);
    } else {
      setModal({open: true, mode, data});
    }
  }
  const gridProps = getDataGridProps(props, onEditClick, confirm, modal);
  React.useEffect(() => {
    if (modal.open === true) {
      props.onAction(modal.mode === 'add' ? 'addOpen' : (modal.mode === 'copy' ? 'copyOpen' : 'editOpen'), modal.data);
      if (props.isStaffEdit && modal.mode === 'edit') {
        const workStart = modal.data.empDate || 0;
        const workEnd = modal.data.workEnd || util.getCurrentDate();
        const workingYear = util.getYearFromDays(util.diffDays(workStart, workEnd));
        const textYear = workingYear > 1 ? 'years' : 'year';
        setTitle(`${props.editLabel} (${workingYear} ${textYear})`);
      } else {
        setTitle(modal.mode === 'add' ? props.addLabel : (modal.mode === 'copy' ? props.copyLabel : props.editLabel));
      }
    } else {
      props.onAction('modalClose', undefined);
    }
  }, [modal.open]);
  const fields = (props.useExtendedColDef === true ? gridProps.columns : (typeof props.fields === 'function' ? props.fields(modal) : props.fields)) ?? [];
  const onDelete = (row) => {
    confirm.show('Confirm', 'Are you sure to delete?', async () => {
      const res = await props.onAction('delete', row);
      if (res) {
        util.showSuccess(`${props.label} has been deleted successfully!`);
        await queryGridData(props.name, undefined, props.onQuery);
        setModal({open: false});
      }
    }, 'warning');
  };
  const onCopy = (row) => {
    confirm.show('Confirm', 'Are you sure to copy?', async () => {
      const res = await props.onAction('copy', row);
      if (res) {
        util.showSuccess(`${props.label} has been copied successfully!`);
        await queryGridData(props.name, undefined, props.onQuery);
        setModal({open: false});
      }
    }, 'warning');
  };
  return (
    <>
      {props.useModalDataGrid === true ? <ModalDataGrid {...gridProps} padding={props.padding} width={props.modalWidth} height={props.modalDataGridHeight} /> : <DataGrid {...gridProps} />}
      {props.renderForm && (
        <Modal isOpen={modal.open} className={'modal-dialog--form'} centered style={{width: props.modalWidth ?? 540}}>
          <Form
            onFormik={props.onFormik}
            title={title}
            horizontal
            fields={fields}
            values={modal.data}
            onSubmit={async values => {
              const res = await props.onAction(modal.mode, values);
              if (res) {
                util.showSuccess(`${props.label} has been ${modal.mode === 'add' ? 'added' : (modal.mode === 'copy' ? 'copied' : 'updated')} successfully!`);
                await queryGridData(props.name, undefined, props.onQuery);
                setModal({open: false});
              } else {
                if (props.doNotShowErrorMessage !== true) {
                  return util.showError(res.data.err_message);
                }
              }
            }}
            onDelete={props.showDeleteButton === true ? onDelete : undefined}
            onCopy={props.showCopyButton === true ? onCopy : undefined}
            onCancel={() => setModal({open: false})}
            render={(formik, fields, errors) => props.renderForm(formik, fields, errors)}
           />
        </Modal>
      )}
      {confirm.render()}
    </>
  );
};

function getEditData(row: Object, props: DataGridViewProps, modal: ModalState, action) {
  if (props.useExternalForm === true) {
    return row;
  }
  if (props.useExtendedColDef === true) {
    const values = {};
    const columns = typeof props.columns === 'function' ? props.columns(modal) : props.columns;
    for (const column of columns) {
      if (column.type === 'date') {
        values[column.name] = util.formatDate(row[column.field], 'YYYY-MM-DD');
      } else {
        values[column.name] = row[column.field];
      }
    }
    let valuesInfo = {...values};
    if(action === 'copy') {
      valuesInfo = {...values, id: undefined, etaDate: undefined, etaTime: undefined, etdDate: undefined, etdTime: undefined, ccount: undefined}
    }
    return valuesInfo;
  } else {
    return util.valuesFromListData(row, typeof props.fields === 'function' ? props.fields(modal) : props.fields, props.nameMap, props.dateFields)
  }
}

function createActionCell(props: DataGridViewProps, onEditClick, confirm, modal: ModalState) {
  const actions = typeof props.actions === 'function' ? props.actions() : props.actions;
  if (actions && actions.length > 0) {
    const cellRendererParams = {isDisabled: props.isActionDisabled};
    for (const action of actions) {
      switch (action) {
        case 'edit':
          cellRendererParams['onEditClick'] = row => {
            onEditClick(
              'edit',
              getEditData(row, props, modal, action),
            );
          };
          break;
        case 'delete':
          cellRendererParams['onDeleteClick'] = row => {
            confirm.show('Confirm', 'Are you sure to delete?', async () => {
              const res = await props.onAction('delete', row);
              if (res) {
                util.showSuccess(`${props.label} has been deleted successfully!`);
                await queryGridData(props.name, undefined, props.onQuery);
              }
            }, 'warning');
          };
          break;
        case 'detail':
          cellRendererParams['onDetailClick'] = row => props.onAction('detail', row);
          break;
        case 'addChild':
          cellRendererParams['onAddChildClick'] = row => props.onAction('addChild', row);
          break;
        case 'history':
          cellRendererParams['onHistoryClick'] = row => props.onAction('history', row);
          break;
        case 'print':
          cellRendererParams['onPrintClick'] = row => props.onAction('print', row);
          break;
        case 'invoice':
          cellRendererParams['onInvoiceClick'] = row => props.onAction('invoice', row);
          break;
        case 'commission':
          cellRendererParams['onCommissionClick'] = row => props.onAction('commission', row);
          break;
        case 'partnerHistory':
          cellRendererParams['onPartnerHistoryClick'] = row => props.onAction('partnerHistory', row);
          break;
        case 'copy':
          cellRendererParams['onCopyClick'] = row => {
            onEditClick(
              'copy',
              getEditData(row, props, modal, action),
            );
          };
          break;
        case 'email':
          cellRendererParams['onEmailClick'] = row => props.onAction('email', row);
          break;
        case 'blMake':
          cellRendererParams['onBlMakeClick'] = row => props.onAction('blMake', row);
          break;
      }
    }
    cellRendererParams['actionTooltips'] = props.actionTooltips;
    return {
      headerName: 'Actions',
      width: props.actionWidth,
      minWidth: props.actionWidth,
      cellRendererFramework: ActionCell,
      cellRendererParams,
    };
  }
}

function getDataGridProps(props: DataGridViewProps, onEditClick, confirm, modal: ModalState): DataGridProps {
  const {
    name, sortCol, sortDesc, pageRows,
    addLabel,
    columns,
    showDateSearch,
    onQuery,
    onRowClick,
    onRowDoubleClick,
    onCellClick,
    onCellValueChange,
    hideSearchInput,
    sortableColumns,
    initialSearchToday,
    initialSearchOneWeek,
    initialSearchOneMonth,
    initialSearchOneYear,
    initialSearchLastMonth,
    initialSearchCurrentYear,
    initialSearchLastMonthToToday,
    initialSearchDateNone,
    showRowSearch,
    doNotPerformInitialQuery,
    onGridReady,
    rowSelection,
    rowMultiSelectWithClick,
    onSelectionChanged,
    qryText,
    dataGridHeight,
    showBranch,
  } = props;
  const cols = typeof columns === 'function' ? columns(modal) : columns;
  const actionCol = createActionCell(props, onEditClick, confirm, modal);
  return {
    name,
    columns: actionCol ? [actionCol, ...cols] : cols,
    gridInfo: {
      qryText: '',
      columns,
      orderBy: sortCol,
      isDesc: sortDesc,
      rowCount: pageRows,
    },
    leftButton: addLabel ? {label: addLabel, onClick: () => onEditClick('add')} : undefined,
    showDateSearch,
    onQuery,
    onRowClick,
    onRowDoubleClick,
    onCellClick,
    onCellValueChange,
    hideSearchInput,
    sortableColumns,
    initialSearchToday,
    initialSearchOneWeek,
    initialSearchOneMonth,
    initialSearchOneYear,
    initialSearchLastMonth,
    initialSearchCurrentYear,
    initialSearchLastMonthToToday,
    initialSearchDateNone,
    showRowSearch: showRowSearch ?? true,
    doNotPerformInitialQuery,
    onGridReady,
    rowSelection,
    rowMultiSelectWithClick,
    onSelectionChanged,
    qryText,
    dataGridHeight,
    showBranch,
  };
}

function getDataGridInfo(props: DataGridViewProps) {
  return {
    qryText: '',
    orderBy: props.sortCol,
    isDesc: props.sortDesc,
    rowCount: props.pageRows,
  };
}

export interface UseDataGridView {
  props: DataGridViewProps;
  query: (gridInfo?: DataGridInfo) => Promise<any>;
  render: () => React$Node;
}

export function useDataGridView({onAction, onQuery, ...props}: DataGridViewProps): UseDataGridView {
  const asyncStatusModal = useAsyncStatusModal('Searching... Please wait...');
  const actionHandler = (action, data) => {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${props.label} action`, action, data);
    return onAction(action, data);
  };
  const queryHandler = (gridInfo) => {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${props.label} query`, gridInfo);
    // console.log('hello open async modal');
    asyncStatusModal.open();
    return onQuery(gridInfo).finally(() => {
      // console.log('hello close async modal');
      asyncStatusModal.close();
    });
  }
  const gridViewProps = {...props, onAction: actionHandler, onQuery: queryHandler};
  React.useEffect(() => {
    return () => {
      updateDataGridInfo(util.dispatch, gridViewProps.name, getDataGridInfo(props)); // 컴퍼넌트가 언마운트 될때 그리드의 쿼리 정보를 기본 값으로 리셋함!
    };
  }, []);
  return {
    props: gridViewProps,
    query: (gridInfo) => queryGridData(gridViewProps.name, gridInfo, gridViewProps.onQuery),
    render: () => <><DataGridView {...gridViewProps} />{asyncStatusModal.render()}</>
  };
}

export default DataGridView;
