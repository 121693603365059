/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, apiSettings, util} from '../../services/service';
import type {FormField} from '../../components/Form';
import {renderField} from '../../components/Form';
import {ColDef} from 'ag-grid-community';
import {useSimpleModal} from "../../components/SimpleModal";
import {useAsyncStatusModal} from "../../components/AsyncStatusModal";
import {useDropzone} from "react-dropzone";
import UploadIcon from "mdi-react/UploadIcon";
import {DefaultButton} from "../../components/buttons";
import {useUser} from "../../redux/reducers/userReducer";
import {useNoticeListModal} from "../../modals/newCoship/useNoticeListModal";
import type {DataGridActionType} from "../../components/DataGrid";


const NoticeList = () => {
  const qs = util.getQS();
  const {nid} = qs;
  const user = useUser();
  const isManager = user.isManager;
  const [attachFile, setAttachFile] = React.useState('');
  const newFileName = React.useRef('');
  // const readOnly = React.useRef('N');
  const NAME = 'noticeList';
  const LABEL = 'Notice';
  const ADD_LABEL = 'ADD NOTICE';
  const EDIT_LABEL = 'EDIT NOTICE';
  const CATEGORY_LABEL = 'Dashboard';
  const MENU_LABEL = 'Notice';
  const SORT_COL = 'cdate';
  const IS_DESC = true;
  const PAGE_ROWS = 20;
  const MODAL_WIDTH = 860;
  const SHOW_DATE_SEARCH = false;
  const ACTIONS = ['edit', 'delete'];
  const ACTION_WIDTH = 90;
  const areaOptions = api.useAreaOptions(true);
  const noticeListModal = useNoticeListModal();

  async function onAction(action, data) {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
    const ndata = {...data, attach_file: newFileName.current};
    switch (action) {
      case 'add':
        if (isManager) {
          return api.noticeAdd(ndata);
        } else {
          return onError();
        }
      case 'edit':
        if (isManager) {
          return api.noticeEdit(ndata);
        } else {
          return onError();
        }
      case 'delete':
        if (isManager) {
          return api.noticeDel(ndata);
        } else {
          return onError();
        }
      case 'detail':
        return onReset('');
      case 'addOpen':
        return onReset('');
      case 'editOpen':
        return onReset(data?.attach_file ?? '');
      case 'modalClose':
        return onReset('');
    }
  }

  const onError = () => {
    util.showError("You can not add, edit or delete notice.");
  }

  const onReset = (fileName) => {
    // console.log('on reset....................', action);
    // readOnly.current = 'N';
    setAttachFile(fileName);
  }

  // function isActionDisabled(type: DataGridActionType, data: Object) {
  function isActionDisabled(type: DataGridActionType) {
    if (type === 'edit' || type === 'delete') return isManager === false;
  }

  const openNotice = (id) => {
    api.getNotice(id).then(({data}) => {
      noticeListModal.open({...data});
    });
  }

  async function onQuery(gridInfo) {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
    return api.noticeList(gridInfo);
  }

  function renderForm(formik, fields, errors) {
    const {uploadModal} = useSettingMaster();
    const render = (name) => renderField(formik, name, fields, errors);
    const onUpload = () => {
      uploadModal.open();
    }

    return (
      <>
        {render('title')}
        {render('message')}
        {render('branch_id')}
        {render('pop_up')}
        <div className={'w-full'}>
          <div className={'ml-20'}>
            {isManager && <DefaultButton label={'Upload'} onClick={onUpload} noMargin />}
            {/*{attachFile*/}
            {/*    ?  <a href={`/static/notice/${attachFile}`} style={{color: '#000000', textDecorationLine: 'underline'}}><span className={'ml-20'}>Attachment: {attachFile}</span></a>*/}
            {/*    :  <span className={'ml-20'}>Attachment: </span>*/}
            {/*}*/}
            <span className={'ml-20'}>{`Attachment: ${attachFile}`}</span>
          </div>
        </div>
        {uploadModal.render()}
      </>
    );
  }

  function useSettingMaster() {
    const uploadModal = useFileUploadModal((res, fileName) => {
      const message = res?.data?.[0]?.msg;
      if (message) {
        newFileName.current = fileName;
        setAttachFile(fileName);
        util.showSuccess(message);
        uploadModal.close();
      }
    });
    return {uploadModal};
  }

  function getColumns(): (ColDef | FormField)[] {
    const extendedAreaOptions = [{value: 0, label: 'ALL'}, ...areaOptions];
    return [
      {name: 'id', required: false, field: 'id', hide: false, headerName: 'ID'},
      {name: 'branch_id', label: 'Branch', options: extendedAreaOptions, required: false, field: 'branch_id', hide: true, noDefOption: true},
      {name: 'message', type: 'textarea', textAreaRows: 10, required: true, field: 'message', headerName: 'Message', hide: true},
      {name: 'cdate', required: false, field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, flex: 1},
      {name: 'title', required: true, field: 'title', headerName: 'Title', flex: 1},
      {name: 'uname', required: false, field: 'uname', headerName: 'Create Staff', flex: 1},
      {name: 'pop_up', type: 'checkbox', required: false, field: 'pop_up', headerName: 'Pop Up', flex: 1, valueFormatter: util.yesNoFormatter},
      {name: 'branch_name', required: false, field: 'branch_name', headerName: 'Branch', flex: 1},
      {name: 'attach_file', required: false, field: 'attach_file', headerName: 'Attachment', cellRendererFramework: (p) => downPdfFormatter(p)},
    ];
  }

  const CodeUpload = ({onUpload}) => {
    const [fileSize, setFileSize] = React.useState('0 BYTE');
    const asyncStatusModal = useAsyncStatusModal('Uploading... please wait...');
    const onDrop = React.useCallback((acceptedFiles) => {
      const acceptedFile = acceptedFiles[0].size;
      if (acceptedFile >= 0 && acceptedFile < 1000) {
        setFileSize(`${acceptedFile} BYTE`);
      } else if (acceptedFile >= 1000 && acceptedFile < 1000000) {
        setFileSize(`${acceptedFile / 1000} KB`);
      } else if (acceptedFile >= 1000000 && acceptedFile < 1000000000) {
        setFileSize(`${acceptedFile / 1000000} MB`);
      } else {
        setFileSize(acceptedFile);
      }
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
          asyncStatusModal.open();
          apiSettings.uploadNotice(reader.result).then((res) => onUpload(res, file.name)).finally(() => asyncStatusModal.close());
        };
        reader.readAsDataURL(file);
      }
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
      onDrop,
      accept: '.pdf',
      maxFiles: 1,
      maxSize: 20 * 1024 * 1024,
      onDropRejected: () => {
        util.showWarning('Please select valid pdf file!');
      },
    });
    return (
      <>
        <div className={'flex center middle mb-20'} {...getRootProps()} style={{height: 350, border: '1px solid #d0d0d0'}}>
          <input {...getInputProps()} />
          <div style={{textAlign: 'center'}}>
            <UploadIcon size={56} color={'#c0c0c0'} />
            {isDragActive ? <p>Drop the pdf file here ...</p> : <p>Drag & drop pdf file here, or click to select file</p>}
          </div>
          {asyncStatusModal.render()}
        </div>
        <div>{`File size: ${fileSize}`}</div>
      </>
    );
  };

  function useFileUploadModal(onUpload) {
    return useSimpleModal({
      title: 'File Upload',
      width: 620,
      centered: true,
      buttons: [
        // {label: 'Close', onClick: () => modal.close()}
      ],
      children: <CodeUpload onUpload={onUpload}/>,
    });
  }

  React.useEffect(() => {
    if(nid > 0) {
      openNotice(nid);
    }
  }, []);

  return (
      <div>
        <DataGridView
          name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
          addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
          actions={ACTIONS} actionWidth={ACTION_WIDTH}
          showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
          columns={getColumns}
          onAction={async (action, data) => onAction(action, data)}
          onQuery={async (gridInfo) => onQuery(gridInfo)}
          renderForm={(formik, fields, errors) => renderForm(formik, fields, errors)}
          isActionDisabled={isActionDisabled}
          useExtendedColDef
        />
        {noticeListModal.render()}
      </div>
  );
};

export default NoticeList;

export function downPdfFormatter(p) {
  if(p.value) {
    return (
        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
            e.preventDefault();
            util.openTab(`/static/notice/${p.value}`);
          }}>
            {p.value}
          </a>
        </div>
    );
  }
  return p.value;
}