/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {partnerTypeOptions, pickupTimeOptions, labelTypeOptions} from '../helper';
import {api, util} from '../../services/service';
import {StaffSearchInput} from '../../components/SearchInput';
import {useUser} from '../../redux/reducers/userReducer';

const FN = {
  ID: 'id',
  PARTNER_TYPE: 'partnerType',
  BRANCH_ID: 'branchId',
  STAFF: 'staff',
  STAFF_ID: 'staffId',
  IMPORT_NO: 'importNo',
  NAME_ENG: 'engName',
  NAME: 'localName',
  COUNTRY: 'country',
  CITY: 'city',
  POSTAL: 'postalCode',
  ALIAS: 'alias',
  PROVINCE: 'province',
  ADDRESS: 'localAddress',
  BILLING_ADDRESS: 'billAddress',
  BL_NAME: 'blName',
  CEO: 'ceo',
  IDENTIFICATION: 'idNo',
  TAX_ID_NO: 'taxidNo',
  CORP_ID_NO: 'corpNo',
  ACCOUNT_GROUP_ID: 'accGroupId',
  IATA: 'iata',
  TSA: 'shipperId',
  PREFIX: 'prefix',
  SCAC: 'scac',
  CBSA_SCAC: 'cbsaScac',
  KAMS: 'kams',
  ACTIVE: 'isActive',
  PHONE: 'phone',
  CELL: 'cell',
  FAX: 'fax',
  EMAIL: 'email',
  REMARK: 'remark',
  JOB_ID: 'jobId',
  DISCOUNT: 'discountRate',
  PICKUP_DAYS: 'pickupDays',
  PICKUP_TIME: 'pickupTime',
  PASSWORD: 'pwd',
  SUN_CHECK: 'sunCheck',
  MON_CHECK: 'monCheck',
  TUE_CHECK: 'tueCheck',
  WED_CHECK: 'wedCheck',
  THU_CHECK: 'thuCheck',
  FRI_CHECK: 'friCheck',
  SAT_CHECK: 'satCheck',
  VENDOR_ID: 'vendorId',
  LABEL_PRINT: 'labelPrint',
  HS_CODE: 'hscode',
  LABEL_TYPE: 'labelType',
};
const SN = {
  ID: 'id',
  PARTNER_TYPE: 'partner_type',
  BRANCH_ID: 'branch_id',
  STAFF: 'staff_name',
  STAFF_ID: 'staff_id',
  IMPORT_NO: 'import_no',
  NAME_ENG: 'eng_name',
  NAME: 'local_name',
  COUNTRY: 'country',
  CITY: 'city',
  POSTAL: 'postal_code',
  ALIAS: 'alias',
  PROVINCE: 'province',
  ADDRESS: 'local_address',
  BILLING_ADDRESS: 'bill_address',
  BL_NAME: 'bl_name',
  CEO: 'ceo',
  IDENTIFICATION: 'id_no',
  TAX_ID_NO: 'taxid_no',
  CORP_ID_NO: 'corp_no',
  ACCOUNT_GROUP_ID: 'accgroup_id',
  IATA: 'iata',
  TSA: 'shipper_id',
  PREFIX: 'prefix',
  SCAC: 'scac',
  CBSA_SCAC: 'cbsa_scac',
  KAMS: 'kams',
  ACTIVE: 'isactive',
  PHONE: 'phone',
  CELL: 'cell',
  FAX: 'fax',
  EMAIL: 'email',
  REMARK: 'remark',
  JOB_ID: 'job_id',
  DISCOUNT: 'discount_rate',
  PICKUP_DAYS: 'pickup_day',
  PICKUP_TIME: 'pickup_time',
  PASSWORD: 'pwd',
  SUN_CHECK: 'sunCheck',
  MON_CHECK: 'monCheck',
  TUE_CHECK: 'tueCheck',
  WED_CHECK: 'wedCheck',
  THU_CHECK: 'thuCheck',
  FRI_CHECK: 'friCheck',
  SAT_CHECK: 'satCheck',
  VENDOR_ID: 'vendor_id',
  LABEL_PRINT: 'label_print',
  HS_CODE: 'hscode',
  LABEL_TYPE: 'label_type',
};

class TradePartnerFormManager extends FormManager {
  static FN = FN;
  static SN = SN;
  constructor() {
    super({
      prefix: 'trade-partner-form',
      fields: [
        {name: FN.ID, serverName: SN.ID, label: 'ID'},
        {name: FN.BRANCH_ID, serverName: SN.BRANCH_ID, label: 'Branch', required: true},
        {name: FN.STAFF, serverName: SN.STAFF, label: 'PIC', required: false},
        {name: FN.STAFF_ID, serverName: SN.STAFF_ID, label: 'Staff', required: false},
        {name: FN.PARTNER_TYPE, serverName: SN.PARTNER_TYPE, label: 'Partner Type', options: partnerTypeOptions, required: true},
        {name: FN.NAME_ENG, serverName: SN.NAME_ENG, label: 'Name(Eng.)', required: true},
        {name: FN.NAME, serverName: SN.NAME, label: 'Local Name', required: true},
        {name: FN.ADDRESS, serverName: SN.ADDRESS, label: 'Local Address', type: 'textarea', textAreaRows: 3},
        {name: FN.CITY, serverName: SN.CITY, label: 'City'},
        {name: FN.PROVINCE, serverName: SN.PROVINCE, label: 'Province'},
        {name: FN.POSTAL, serverName: SN.POSTAL, label: 'Postal Code'},
        {name: FN.COUNTRY, serverName: SN.COUNTRY, label: 'Country'},
        {name: FN.IMPORT_NO, serverName: SN.IMPORT_NO, label: 'Import No.'},
        {name: FN.BL_NAME, serverName: SN.BL_NAME, label: 'B/L Name', type: 'textarea', textAreaRows: 3},
        {name: FN.BILLING_ADDRESS, serverName: SN.BILLING_ADDRESS, label: 'Billing Address', type: 'textarea', textAreaRows: 2},
        {name: FN.CEO, serverName: SN.CEO, label: 'CEO'},
        {name: FN.IDENTIFICATION, serverName: SN.IDENTIFICATION, label: 'Identification'},
        {name: FN.TAX_ID_NO, serverName: SN.TAX_ID_NO, label: 'Tax ID No.'},
        {name: FN.CORP_ID_NO, serverName: SN.CORP_ID_NO, label: 'Corporation ID'},
        {name: FN.ACCOUNT_GROUP_ID, serverName: SN.ACCOUNT_GROUP_ID, label: 'Account Group'},
        {name: FN.IATA, serverName: SN.IATA, label: 'IATA(Firms)'},
        {name: FN.TSA, serverName: SN.TSA, label: 'Shipper ID'},
        {name: FN.PREFIX, serverName: SN.PREFIX, label: 'Prefix'},
        {name: FN.SCAC, serverName: SN.SCAC, label: 'SCAC'},
        {name: FN.CBSA_SCAC, serverName: SN.CBSA_SCAC, label: 'CBSA SCAC'},
        {name: FN.KAMS, serverName: SN.KAMS, label: 'KAMS'},
        {name: FN.ACTIVE, serverName: SN.ACTIVE, label: 'Active', type: 'checkbox'},
        {name: FN.PHONE, serverName: SN.PHONE, label: 'Phone #'},
        {name: FN.CELL, serverName: SN.CELL, label: 'Cell #'},
        {name: FN.FAX, serverName: SN.FAX, label: 'Fax #'},
        {name: FN.EMAIL, serverName: SN.EMAIL, label: 'Email (Login)', useSameAsInput: true},
        {name: FN.REMARK, serverName: SN.REMARK, label: 'Remark', type: 'textarea', textAreaRows: 2},
        {name: FN.JOB_ID, serverName: SN.JOB_ID, label: ''},
        {name: FN.DISCOUNT, serverName: SN.DISCOUNT, label: 'Discount Rate'},
        {name: FN.PASSWORD, serverName: SN.PASSWORD, label: 'password', useSameAsInput: true},
        {name: FN.PICKUP_DAYS, serverName: SN.PICKUP_DAYS, label: ''},
        {name: FN.PICKUP_TIME, serverName: SN.PICKUP_TIME, label: 'Pickup Time', options: pickupTimeOptions},
        {name: FN.SUN_CHECK, serverName: SN.SUN_CHECK, type: 'checkbox'},
        {name: FN.MON_CHECK, serverName: SN.MON_CHECK, type: 'checkbox'},
        {name: FN.TUE_CHECK, serverName: SN.TUE_CHECK, type: 'checkbox'},
        {name: FN.WED_CHECK, serverName: SN.WED_CHECK, type: 'checkbox'},
        {name: FN.THU_CHECK, serverName: SN.THU_CHECK, type: 'checkbox'},
        {name: FN.FRI_CHECK, serverName: SN.FRI_CHECK, type: 'checkbox'},
        {name: FN.SAT_CHECK, serverName: SN.SAT_CHECK, type: 'checkbox'},
        {name: FN.VENDOR_ID, serverName: SN.VENDOR_ID, label: 'Vendor No.'},
        {name: FN.LABEL_PRINT, serverName: SN.LABEL_PRINT, label: 'Label Print', type: 'checkbox'},
        {name: FN.HS_CODE, serverName: SN.HS_CODE, label: 'HS code', mask: util.MASK_NUMBER_NO_DECIMAL},
        {name: FN.LABEL_TYPE, serverName: SN.LABEL_TYPE, label: 'Label Type', options: labelTypeOptions, noDefOption: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const user = useUser();
    const areaOptions = api.useAreaOptions(true);
    _f(FN.BRANCH_ID).options = areaOptions;
    // React.useEffect(() => {
    //   if (!_v(FN.BRANCH_ID)) {
    //     _c(FN.BRANCH_ID, user.branch_id);
    //   }
    //   _c(FN.ACTIVE, true);
    // }, []);
    React.useEffect(() => {
      if(!_v(FN.STAFF)) {
        _c(FN.STAFF_ID, null);
      }
    }, [_v(FN.STAFF)]);
    React.useEffect(() => {
      if ((areaOptions??[]).length > 0) {
        if (!_v(FN.BRANCH_ID)) {
          _c(FN.BRANCH_ID, user.branch_id);
        }
        if (_v(FN.ACTIVE) === undefined) {
          _c(FN.ACTIVE, true);
        }
      }
    }, [areaOptions]);
    React.useEffect(() => {
      _f(FN.ACTIVE).onChangeColor = '#ffffff';
    }, []);
    const labelType = _v(FN.LABEL_TYPE);
    React.useEffect(() => {
      if(!labelType) {
        _c(FN.LABEL_TYPE, 'LETTER');
      }
    } ,[labelType]);
    return (
      <>
        <div className={'flex w-full mr-20'}>
          <div className={'flex-1 mr-20'}>
            <div className={'flex'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <StaffSearchInput
                className={'flex-1'}
                displayField={_n(FN.STAFF)}
                idField={_n(FN.STAFF_ID)}
                formik={formik} fields={fields} errors={errors}
              />
            </div>
            <div className={'flex'}>
              <div className={'flex-1'}>{_r(FN.PARTNER_TYPE)}</div>
              {_v(FN.PARTNER_TYPE) === 'BR' || _v(FN.PARTNER_TYPE) === 'VD' || _v(FN.PARTNER_TYPE) === 'DZ' ? <div className={'flex-1'}>{_r(FN.LABEL_TYPE)}</div> : <div className={'flex-1'} />}
            </div>
            <div className={'flex'}>
              {
                _v(FN.PARTNER_TYPE) === 'VD'
                  ? <><div className={'flex-1'}>{_r(FN.VENDOR_ID)}</div><div className={'flex-1'}>{_r(FN.HS_CODE)}</div></>
                  : <div className={'flex-1'} />
              }
            </div>
            <div className={'flex'}>
              {_r(FN.NAME)}
              {_r(FN.NAME_ENG)}
            </div>
            <div className={'flex'}>
              {_r(FN.PHONE)}
              {_r(FN.CELL)}
            </div>
            <div className={'flex'}>
              {_r(FN.FAX)}
              {_r(FN.EMAIL)}
            </div>
            <div>
              {_r(FN.ADDRESS)}
            </div>
            <div className={'flex'}>
              {_r(FN.CITY)}
              {_r(FN.PROVINCE)}
            </div>
            <div className={'flex'}>
              {_r(FN.POSTAL)}
              {_r(FN.COUNTRY)}
            </div>
          </div>
          <div className={'flex-1'}>
            <div className={'flex'}>
              {_r(FN.BL_NAME)}
            </div>
            <div className={'flex'}>
              {_r(FN.BILLING_ADDRESS)}
            </div>
            <div className={'flex'}>
              {_r(FN.IMPORT_NO)}
              {_r(FN.CEO)}
            </div>
            <div className={'flex'}>
              {_r(FN.IDENTIFICATION)}
              {_r(FN.TAX_ID_NO)}
            </div>
            <div className={'flex'}>
              {_r(FN.CORP_ID_NO)}
              {_r(FN.ACCOUNT_GROUP_ID)}
            </div>
            <div className={'flex'}>
              {_r(FN.IATA)}
              {_r(FN.TSA)}
            </div>
            <div className={'flex'}>
              {_r(FN.PREFIX)}
              {_r(FN.SCAC)}
            </div>
            <div className={'flex'}>
              {_r(FN.CBSA_SCAC)}
              {_r(FN.KAMS)}
            </div>
          </div>
        </div>
        <div className={'flex w-full mr-20'}>
          <div className={'flex-1'}>{_r(FN.PASSWORD)}</div>
          <div className={'flex-2'}>{_r(FN.REMARK)}</div>
          <div className={'flex-1'}>
            {_r(FN.ACTIVE)} {_v(FN.PARTNER_TYPE) === 'VD' && _r(FN.LABEL_PRINT)}
          </div>
        </div>
        {_v(FN.PARTNER_TYPE) === 'DZ' && (
          <div className={'flex w-full mr-20'}>
            <div className={'flex middle mb-20 flex-3'} style={{position: 'relative', marginLeft: 120}}>
              <div style={{position: 'absolute', left: -120, textAlign: 'right', width: 120, paddingRight: 20}}>Pickup Days</div>
              {_r('sunCheck')}
              <span className={'mr-40'}>SUN</span>
              {_r('monCheck')}
              <span className={'mr-40'}>MON</span>
              {_r('tueCheck')}
              <span className={'mr-40'}>THU</span>
              {_r('wedCheck')}
              <span className={'mr-40'}>WED</span>
              {_r('thuCheck')}
              <span className={'mr-40'}>THU</span>
              {_r('friCheck')}
              <span className={'mr-40'}>FRI</span>
              {_r('satCheck')}
              <span className={'mr-40'}>SAT</span>
            </div>
            <div className={'flex-1'}>{_r(FN.PICKUP_TIME)}</div>
          </div>
        )}
      </>
    );
  };
  onValidate = (values) => {
    for (const field of this.fields) {
      const name = field.name.replace(`${this.prefix}-`, '');
      if (field.required === true && !values[name]) {
        util.showWarning(`Please fill all the required fields`);
        return;
      }
    }
    return values;
  };
}

export default TradePartnerFormManager;
