/**
 * @flow
 */
import React from 'react';
import FormManager from "../../lib/FormManager";
// import {api, util} from '../../services/service';
// import {DefaultButton} from '../../components/buttons';
// import {apiCourier, apiForwarding, apiMoving} from "../../services/newCoship/service";
// import {ColDef} from "ag-grid-community";
// import {FD, FN, unitOptions} from "../field-defs/newCoship";
// import {useUser} from "../../redux/reducers/userReducer";

class NoticeFormManager extends FormManager {
  notiData;
  constructor(data) {
    super({
      prefix: `setting-notice-add-item-form`,
      fields: [
        {name: 'title', serverName: 'title', label: 'Title', disabled: true},
        {name: 'message', serverName: 'message', label: 'Message', type: "textarea", textAreaRows: 25, disabled: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.notiData = data;
  }

  onRender = () => {
    // const [nData, setNData] = React.useState({});
    const {_r, _c} = this.getRenderProps();
    React.useEffect(() => {
      this.setValues(this.notiData);
    }, []);

    return (
      <div className={'w-full'}>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex-1 mr-2'}>{_r('title')}</div>
        </div>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex-1 mr-2'}>{_r('message')}</div>
        </div>
        <div className={'flex between w-full mb-2'}>
            {this.notiData?.attach_file
                ?  <a href={`/static/notice/${this.notiData.attach_file}`} style={{color: '#000000', textDecorationLine: 'underline'}} target="_blank" rel="noopener noreferrer">
                    <span className={'ml-20'}>Attachment: {this.notiData.attach_file}</span>
                  </a>
                :  <span className={'ml-20'}>Attachment: </span>
            }
        </div>
      </div>
    );
  };

  // onValidate = (values) => {return values};
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };

}

export default NoticeFormManager;
